* {
  margin: 0;
  padding: 0;
}
.wallpaper1as {
  background-image: url(../images/leadwall.png);
  background-size: cover;
  height: 110vh;
  width: 100%;
}
.marginforbutton {
  margin-top: 5%;
}
.paragraphdiv {
  display: flex;
  justify-content: center;
  align-content: center;
}
.headingwallpaper1 {
  text-align: center;
  position: relative;
  top: 20%;
}
.h2wallpaper1 {
  text-transform: uppercase;
  font-size: 40px;
  color: rgba(255, 255, 255, 0.877);
}
.h1wallpaper1 {
  text-align: center;
  font-size: 60px;
  font-weight: 700;
  color: white;
}
.h1textaligncenteras {
  margin-top: 50px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: grey;
  margin-left: 150px;
  margin-right: 150px;
  margin-bottom: 100px;
}
.imggrid1 {
  height: 40vh;
  width: 45vh;
  margin-left: 50px;
}
.bow {
  border-top: 3px solid rgb(0, 140, 255);
}
.leftgrid1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 100px;
  margin-left: 150px;
  margin-right: 150px;
  margin-bottom: 100px;
}
.leftside1 {
  margin-top: 50px !important;
  font-size: 40px;
  font-weight: 400;
  color: black;
}
.content2part2lgas {
  margin-left: 150px;
  margin-right: 150px;
  margin-bottom: 100px;
  padding-bottom: 120px;
  border-bottom: #1f85de solid 2px;
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.h1part2as {
  text-align: center;
  position: relative;
}
.marketlgas {
  padding-right: 5%;
  width: 30vw;
  height: 40vh;
}
.parah5as {
  font-weight: 400;
  font-size: 20px;
  padding-top: 30px;
  text-align: center;
  padding-bottom: 40px;
  color: gray;
}
.authorlgas {
  text-align: center;
  color: gray;
  font-weight: 500;
  margin-top: 20px;
}
.h2part2as {
  padding-top: 30px;
  text-align: center;
  font-size: 24px;
  letter-spacing: 2px;
  font-style: italic;
  font-weight: 500;
  margin-left: 20px;
}
.bor {
  border-bottom: 3px solid rgb(0, 140, 255);
  padding-bottom: 100px;
}
.h1textaligncenter1 {
  margin-left: 250px;
  margin-right: 250px;
  margin-bottom: 100px;
  font-size: 40px;
  font-weight: 600;
  color: black;
  text-align: center;
}
.smallCrdInMarketKV {
  margin-left: 150px;
  margin-right: 150px;
  min-height: 500px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.worldmap1 {
  margin-top: -150px;
  margin-bottom: 100px;
  margin-left: 150px;
  margin-right: 150px;
}
.worldmap {
  height: 80vh;
  width: 70vw;
}
/* ====================================================================================================================== */
.gridleftimage {
  margin-left: 150px;
  margin-right: 150px;
  margin-bottom: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.h1part1as {
  font-size: 40px !important;
  font-weight: 400;
}

.imageingrid {
  width: 30vw;
  height: 40vh;
}
.paradetails {
  font-weight: 400;
  text-align: left;
  color: gray;
  margin-top: 30px;
  font-size: 20px;
  margin-bottom: 40px;
}
@media (min-width: 996px) and (max-width: 1280px) {
  .wallpaper1as {
    background-image: url(../images/leadwall.png);
    background-size: cover;
    height: 50vh;
    width: 100%;
  }
  .leftgrid1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 100px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 100px;
  }
  .imggrid1 {
    height: 30vh;
    width: 30vh;
    margin-left: 25px;
    margin-top: 0px;
  }

  .imageingrid {
    margin-top: 0px;
    margin-bottom: 50px;
    width: 40vw;
    height: 30vh;
    margin-left: 0;
  }
  .gridleftimage {
    margin-top: 100px;
    margin-left: 5%;
    margin-right: 0;
    margin-bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .smallCrdInMarketKV {
    margin-left: 50px;
    margin-right: 50px;
    min-height: auto;
  }
  .worldmap1 {
    margin-top: -0px;
    margin-bottom: 100px;
    margin-left: 50px;
    margin-right: 50px;
  }
  .worldmap {
    height: auto;
    width: 100%;
  }
  .h1textaligncenter1 {
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 100px;
    font-size: 40px;
    font-weight: 600;
    color: black;
    text-align: center;
  }
}
@media (min-width: 700px) and (max-width: 996px) {
  .worldmap1 {
    margin-top: -0px;
    margin-bottom: 100px;
    margin-left: 50px;
    margin-right: 50px;
  }
  .worldmap {
    height: 50vh;
    width: 100%;
  }
  .imggrid1 {
    height: 40vh;
    width: 45vh;
    margin-left: 50px;
    margin-top: 50px;
  }
  .leftgrid1 {
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 100px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 100px;
  }
  .wallpaper1as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 70vh;
    width: 100%;
  }
  .h1textaligncenter1 {
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 100px;
    font-size: 40px;
    font-weight: 600;
    color: black;
    text-align: center;
  }
  .gridleftimage {
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .imageingrid {
    margin-top: 100px;
    margin-bottom: 50px;
    width: 60vw;
    height: 40vh;
    margin-left: 10%;
  }
  .paradetails {
    font-weight: 400;
    text-align: center;
    color: gray;
    margin-top: 30px;
    font-size: 16px;
    margin-bottom: 40px;
  }
  .smallCrdInMarketKV {
    margin-left: 50px;
    margin-right: 50px;
    min-height: 500px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 400px) and (max-width: 700px) {
  .h1part1as {
    font-size: 30px !important;
    font-weight: 400;
  }
  .leftside1 {
    margin-top: 50px !important;
    font-size: 20px;
    font-weight: 400;
    color: black;
    margin-left: 0;
  }
  .h2wallpaper1 {
    text-transform: uppercase;
    font-size: 25px !important;
    color: rgba(255, 255, 255, 0.877);
  }
  .h1wallpaper1 {
    text-align: center;
    font-size: 30px !important;
    font-weight: 700;
    color: white;
  }
  .worldmap1 {
    margin-top: 100px;
    margin-bottom: 100px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .worldmap {
    height: 30vh;
    width: 100%;
  }
  .imggrid1 {
    height: 40vh;
    width: 40vh;
    margin-left: 0px;
    margin-top: 50px;
  }
  .leftgrid1 {
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 100px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 100px;
  }
  .wallpaper1as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 100vh !important;
    width: 100%;
  }
  .h1textaligncenter1 {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 100px;
    font-size: 20px;
    font-weight: 600;
    color: black;
    text-align: center;
  }
  .h1textaligncenteras {
    margin-top: 50px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: grey;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 100px;
  }
  .gridleftimage {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: 1fr;
  }
  .imagecontainer {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .imageingrid {
    margin-top: 100px;
    margin-bottom: 50px;
    height: 40vh !important;
    width: 40vh !important;
    margin-left: 50px;
  }
  .paradetails {
    font-weight: 400;
    text-align: center;
    color: gray;
    margin-top: 30px;
    font-size: 16px;
    margin-bottom: 40px;
  }
  .smallCrdInMarketKV {
    margin-left: 10%;
    margin-right: 0px;
    min-height: 500px;
    display: grid;
    grid-template-columns: 1fr 1fr !important;
  }
}

@media (max-width: 400px) {
  .h1part1as {
    font-size: 30px !important;
    font-weight: 400;
  }
  .leftside1 {
    margin-top: 50px !important;
    font-size: 20px;
    font-weight: 400;
    color: black;
    margin-left: 0;
  }
  .h2wallpaper1 {
    text-transform: uppercase;
    font-size: 25px !important;
    color: rgba(255, 255, 255, 0.877);
  }
  .h1wallpaper1 {
    text-align: center;
    font-size: 30px !important;
    font-weight: 700;
    color: white;
  }
  .worldmap1 {
    margin-top: 100px;
    margin-bottom: 100px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .worldmap {
    height: 30vh;
    width: 100%;
  }
  .imggrid1 {
    height: 40vh;
    width: 40vh;
    margin-left: 0px;
    margin-top: 50px;
  }
  .leftgrid1 {
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 100px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 100px;
  }
  .wallpaper1as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 100vh !important;
    width: 100%;
  }
  .h1textaligncenter1 {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 100px;
    font-size: 20px;
    font-weight: 600;
    color: black;
    text-align: center;
  }
  .h1textaligncenteras {
    margin-top: 50px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: grey;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 100px;
  }
  .gridleftimage {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: 1fr;
  }
  .imagecontainer {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .imageingrid {
    margin-top: 100px;
    margin-bottom: 50px;
    height: 40vh !important;
    width: 40vh !important;
    margin-left: 50px;
  }
  .paradetails {
    font-weight: 400;
    text-align: center;
    color: gray;
    margin-top: 30px;
    font-size: 16px;
    margin-bottom: 40px;
  }
  .smallCrdInMarketKV {
    margin-left: 10%;
    margin-right: 0px;
    min-height: 500px;
    display: grid;
    grid-template-columns: 1fr 1fr !important;
  }
}
