* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.typicalword {
  color: white;
  font-weight: 800;
}

.whole {
  background-color: white;
}

.imgsrccircle {
  z-index: 7;
  margin-top: -100px;
}

.bulaab {
  display: none;
}

.topper {
  font-weight: 400;
  font-size: 50px;
}

.daspal {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* =================================================== */
.gridforblub {
  margin-bottom: 10%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

lottie-player {
  width: 700px !important;
  margin-right: 00px;
  margin-bottom: 100px;
  margin-left: 00px !important;
  z-index: -1;
}

.aham {
  width: 100%;
  height: auto;
}

.movingupdown {
  margin-right: 25px;
  text-align: right;
}

.circlepath {
  z-index: 2;
  margin-top: 100px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}

.movingupdown1 {
  margin-right: 0px;

  text-align: right;
}

.movingupdown2 {
  margin-right: -50px;
  text-align: right;
}

.movingupdown3 {
  margin-right: -75px;
  text-align: right;
}

.movingupdown4 {
  text-align: left;
}
.promo{
  display: none;
}
.movingupdown5 {
  margin-left: -50px;
  text-align: left;
}

.movingupdown6 {
  margin-left: -100px;
  text-align: left;
}

.movingupdown7 {
  margin-left: -125px;
  text-align: left;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1.2);
  }
}

@keyframes shake-mix {
  0% {
    transform: translate(0);
  }

  50% {
    transform: translate(-12px, 12px);
  }

  100% {
    transform: translate(0);
  }
}

/* ============================================= */
.landingcoverKV {
  background-image: url(../images/womanlanding.jpeg);
  background-size: cover;
  height: 125vh;
  margin-top: -10px;
}

.headimgcarddj {
  text-transform: capitalize;
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 5%;
}

.chonesize {
  font-size: 35px;
}

.h1hKV {
  color: white;

  position: absolute;
  top: 40%;
  margin-left: 5%;
  font-size: 50px;
}

.buttonh1as {
  padding-top: 35%;
  padding-left: 10%;
}

.marketingcsasdj {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-left: 10%;
  margin-right: 10%;
}

.framerac {
  margin-left: 10%;
  width: 80vw;
  height: 80vh;
  margin-top: 5%;
  margin-bottom: 5%;
}

.greyas {
  margin-top: -20%;
  background-image: url(../images/greyb.png);
  background-size: cover;
  min-height: 300vh;
  width: 100%;
  padding-top: 40%;
}

.headimgcarddjas {
  margin-top: -5%;
  font-size: 50px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 3px;
}

.buttongreendj {
  margin-left: 42%;
  margin-bottom: 10%;
  margin-top: 7%;
}

.bodyfunnel {
  display: none;
}

@media (min-width: 996px) and (max-width: 1280px) {
  lottie-player {
    width: 996px !important;
    margin-right: 00px;
    margin-left: 00px !important;
    z-index: -1;
  }

  .aham {
    display: none !important;
  }

  .marketingcsasdj {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    margin-left: 100px !important;
  }

  .landingcoverKV {
    background-image: url(../images/womanlanding.jpeg);
    background-size: cover;
    height: 90;
    margin-top: -10px;
  }

  .imgpngforsvg {
    padding-left: 5%;
    width: 350px;
    height: 450px;
  }

  .movingupdown {
    display: none !important;
    margin-left: 100px;
    margin-bottom: 50px;

    margin-bottom: 50px;
  }

  .movingupdown:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown1 {
    display: none !important;
    margin-left: 100px;
    margin-bottom: 50px;

    margin-bottom: 50px;
  }

  .movingupdown1:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown2 {
    display: none !important;
    margin-left: 300px;
    margin-bottom: 50px;

    margin-bottom: 50px;
  }

  .movingupdown2:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown3 {
    display: none !important;
    display: block;
    margin-left: 300px;
    margin-bottom: 50px;

    margin-bottom: 50px;
  }

  .movingupdown3:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown4 {
    display: none !important;
    margin-left: 300px;
    margin-bottom: 50px;

    margin-bottom: 50px;
  }

  .movingupdown4:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown5 {
    display: none !important;
    margin-left: 300px;
    margin-bottom: 50px;

    margin-bottom: 50px;
  }

  .movingupdown5:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown6 {
    display: none !important;
    margin-left: 100px;
    margin-bottom: 50px;

    margin-bottom: 50px;
  }

  .movingupdown6:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown7 {
    display: none !important;
    display: block;
    margin-left: 100px;
    margin-bottom: 50px;

    margin-bottom: 50px;
  }

  .movingupdown7:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .gridforblub {
    display: grid;
    grid-template-columns: 1fr;
  }

  .circlepath {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .buttonh1as {
    padding-top: 75%;
    padding-left: 10%;
  }
}

@media (min-width: 700px) and (max-width: 996px) {
  .imgsrccircle {
    width: 50vw;
    height: auto;
  }

  .aham {
    display: none !important;
  }

  .bodyfunnel {
    display: block;
  }

  lottie-player {
    width: 730px !important;
    margin-right: 00px;
    margin-top: -200px;
    margin-bottom: -100px;
    margin-left: 00px !important;
    z-index: -1;
  }

  .greyas {
    margin-top: -20%;
    background-image: url(../images/greyb.png);
    background-size: cover;
    min-height: auto;
    width: 100%;
    padding-top: 40%;
  }

  .framerac {
    margin-left: 10%;
    width: 80vw;
    height: 80vh;
    margin-top: -20%;
    margin-bottom: -20%;
  }

  .buttonh1as {
    padding-top: 62%;
    padding-left: 10%;
  }

  .h1hKV {
    color: white;
    font-weight: bold;
    position: absolute;
    top: 20%;
    margin-left: 5%;
    font-size: 35px;
  }

  .landingcoverKV {
    background-image: url(../images/womanlanding.jpeg);
    background-size: cover;
    height: 90vh;
    margin-top: -10px;
  }

  .marketingcsasdj {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    margin-left: 2%;
    margin-right: 2%;
  }

  .marketingcsasdj {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    margin-left: 100px !important;
  }

  .landingcoverKV {
    background-image: url(../images/womanlanding.jpeg);
    background-size: cover;
    height: 90;
    margin-top: -10px;
  }

  .imgpngforsvg {
    padding-left: 5%;
    width: 350px;
    height: 450px;
  }

  .movingupdown {
    display: none !important;
    margin-left: 50px;
    margin-bottom: 50px;

    margin-bottom: 50px;
  }

  .movingupdown:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown1 {
    display: none !important;
    margin-left: 50px;
    margin-bottom: 50px;

    margin-bottom: 50px;
  }

  .movingupdown1:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown2 {
    display: none !important;
    margin-left: 150px;
    margin-bottom: 50px;

    margin-bottom: 50px;
  }

  .movingupdown2:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown3 {
    display: none !important;
    display: block;
    margin-left: 150px;
    margin-bottom: 50px;

    margin-bottom: 50px;
  }

  .movingupdown3:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown4 {
    display: none !important;
    margin-left: 150px;
    margin-bottom: 50px;

    margin-bottom: 50px;
  }

  .movingupdown4:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown5 {
    display: none !important;
    margin-left: 150px;
    margin-bottom: 50px;

    margin-bottom: 50px;
  }

  .movingupdown5:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown6 {
    display: none !important;
    margin-left: 50px;
    margin-bottom: 50px;

    margin-bottom: 50px;
  }

  .movingupdown6:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown7 {
    display: none !important;
    display: block;
    margin-left: 50px;
    margin-bottom: 50px;

    margin-bottom: 50px;
  }

  .movingupdown7:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .gridforblub {
    display: grid;
    grid-template-columns: 1fr;
  }

  .circlepath {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  /* .buttonh1as {
    padding-top: 75%;
    padding-left: 10%;
  } */
}

@media (min-width: 400px) and (max-width: 700px) {

  /* ============================================== */
  .aham {
    display: none !important;
  }

  lottie-player {
    width: 400px !important;
    margin-right: 00px;
    margin-top: -200px;
    margin-bottom: -100px;
    margin-left: 00px !important;
    z-index: -1;
  }

  .funneljpg {
    margin-right: 0;
    margin-left: 0;
    z-index: 5;
    width: 70vw;
    height: auto;
  }

  .imgsrccircle {
    width: 70vw;
    height: auto;
  }

  .typicalword {
    color: white;
    font-weight: 800;
    font-size: 30px;
  }

  .chonesize {
    font-size: 18px;
    font-size: 400;
  }

  .topper {
    font-weight: 400;
    font-size: 30px;
  }

  .imgpngforsvg {
    padding-left: 5%;
    width: 350px;
    height: 450px;
  }

  .movingupdown {
    display: none !important;
    margin-left: 25px;
    margin-bottom: 50px;
    width: 100px;
    height: 100px;
    border: 2px solid tomato;
    border-radius: 50%;
    background-color: #f9a71a;
    color: white;
    animation: shake-mix 5s infinite linear;
    margin-bottom: 50px;
  }

  .movingupdown:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown1 {
    display: none !important;
    margin-left: 0px;
    margin-bottom: 50px;
    width: 100px;
    height: 100px;
    border: 2px solid tomato;
    border-radius: 50%;
    background-color: #d45827;
    color: white;
    animation: shake-mix 5s infinite linear;
    margin-bottom: 50px;
  }

  .movingupdown1:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown2 {
    display: none !important;
    margin-left: 100px;
    margin-bottom: 50px;
    width: 100px;
    height: 100px;
    border: 2px solid tomato;
    border-radius: 50%;
    background-color: #407eb5;
    color: white;
    animation: shake-mix 5s infinite linear;
    margin-bottom: 50px;
  }

  .movingupdown2:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown3 {
    display: none !important;
    display: block;
    margin-left: 100px;
    margin-bottom: 50px;
    width: 100px;
    height: 100px;
    border: 2px solid tomato;
    border-radius: 50%;
    background-color: #6a53a2;
    color: white;
    animation: shake-mix 5s infinite linear;
    margin-bottom: 50px;
  }

  .movingupdown3:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown4 {
    display: none !important;
    margin-left: 100px;
    margin-bottom: 50px;
    width: 100px;
    height: 100px;
    border: 2px solid tomato;
    border-radius: 50%;
    background-color: #f9a71a;
    color: white;
    animation: shake-mix 5s infinite linear;
    margin-bottom: 50px;
  }

  .movingupdown4:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown5 {
    display: none !important;
    margin-left: 100px;
    margin-bottom: 50px;
    width: 100px;
    height: 100px;
    border: 2px solid tomato;
    border-radius: 50%;
    background-color: #d45827;
    color: white;
    animation: shake-mix 5s infinite linear;
    margin-bottom: 50px;
  }

  .movingupdown5:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown6 {
    display: none !important;
    margin-left: 20px;
    margin-bottom: 50px;
    width: 100px;
    height: 100px;
    border: 2px solid tomato;
    border-radius: 50%;
    background-color: #407eb5;
    color: white;
    animation: shake-mix 5s infinite linear;
    margin-bottom: 50px;
  }

  .movingupdown6:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown7 {
    display: none !important;
    display: block;
    margin-left: 0px;
    margin-bottom: 50px;
    width: 100px;
    height: 100px;
    border: 2px solid tomato;
    border-radius: 50%;
    background-color: #6a53a2;
    color: white;
    animation: shake-mix 5s infinite linear;
    margin-bottom: 50px;
  }

  .movingupdown7:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .gridforblub {
    display: grid;
    grid-template-columns: 1fr;
  }

  .circlepath {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  /* ======================================================= */
  .buttonh1as {
    padding-top: 75%;
    padding-left: 10%;
  }

  .greyas {
    margin-top: -20%;
    background-image: url(../images/greyb.png);
    background-size: cover;
    min-height: auto;
    width: 100%;
    padding-top: 40%;
  }

  .framerac {
    margin-left: 10%;
    width: 80vw;
    height: 80vh;
    margin-top: -30%;
    margin-bottom: -40%;
  }

  .nodisplay {
    display: none;
  }

  .landingcoverKV {
    background-image: url(../images/womanlanding.jpeg);
    background-size: cover;
    height: 100vh;
    margin-top: -10px;
  }

  .marketingcsasdj {
    display: grid;
    grid-template-columns: 1fr !important;
    margin-left: 10%;
    margin-right: 5%;
  }

  .h1hKV {
    color: white;
    font-weight: bold;
    position: absolute;
    top: 20%;
    margin-left: 5%;
    font-size: 16px !important;
  }

  .bulab {
    display: none;
  }

  .bulaab {
    display: block;
  }

  .promo {
    display: block;
    width: 360px;
  }

}

@media (max-width: 400px) {
  .bodyfunnel {
    display: block;
  }

  .promo {
    display: block;
    width: 360px;
  }

  .aham {
    display: none !important;
  }

  lottie-player {
    width: 360px !important;
    margin-right: 00px;
    margin-top: -200px;
    margin-bottom: -100px;
    margin-left: 00px !important;
    z-index: -1;
  }

  .funneljpg {
    margin-right: 0;
    margin-left: 0;
    z-index: 5;
    width: 70vw;
    height: auto;
    margin-top: -150px;
    margin-bottom: -100px;
  }

  .imgsrccircle {
    width: 70vw;
    height: auto;
  }

  .bulab {
    display: none;
  }

  .bulaab {
    display: block;
  }

  .typicalword {
    color: white;
    font-weight: 800;
    font-size: 20px;
  }

  .chonesize {
    font-size: 18px;
    font-size: 400;
  }

  .topper {
    font-weight: 400;
    font-size: 30px;
  }

  .imgpngforsvg {
    padding-left: 5%;
    width: 350px;
    height: 450px;
  }

  .movingupdown {
    display: none !important;
    margin-left: 25px;
    margin-bottom: 50px;
    width: 100px;
    height: 100px;
    border: 2px solid tomato;
    border-radius: 50%;
    background-color: #f9a71a;
    color: white;
    animation: shake-mix 5s infinite linear;
    margin-bottom: 50px;
  }

  .movingupdown:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown1 {
    display: none !important;
    margin-left: 10px;
    margin-bottom: 50px;
    width: 100px;
    height: 100px;
    border: 2px solid tomato;
    margin-top: 0;
    border-radius: 50%;
    background-color: #d45827;
    color: white;
    animation: shake-mix 5s infinite linear;
    margin-bottom: 50px;
  }

  .movingupdown1:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown2 {
    display: none !important;
    margin-left: 70px;
    margin-bottom: 50px;
    width: 100px;
    height: 100px;
    border: 2px solid tomato;
    border-radius: 50%;
    background-color: #407eb5;
    color: white;
    animation: shake-mix 5s infinite linear;
    margin-bottom: 50px;
  }

  .movingupdown2:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown3 {
    display: none !important;
    display: block;
    margin-left: 80px;
    margin-top: 0;
    margin-bottom: 50px;
    width: 100px;
    height: 100px;
    border: 2px solid tomato;
    border-radius: 50%;
    background-color: #6a53a2;
    color: white;
    animation: shake-mix 5s infinite linear;
    margin-bottom: 50px;
  }

  .movingupdown3:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown4 {
    display: none !important;
    margin-left: 50px;
    margin-bottom: 50px;
    width: 100px;
    height: 100px;
    border: 2px solid tomato;
    border-radius: 50%;
    background-color: #f9a71a;
    color: white;
    animation: shake-mix 5s infinite linear;
    margin-bottom: 50px;
  }

  .movingupdown4:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown5 {
    display: none !important;
    margin-left: 50px;
    margin-bottom: 50px;
    width: 100px;
    height: 100px;
    border: 2px solid tomato;
    border-radius: 50%;
    background-color: #d45827;
    color: white;
    animation: shake-mix 5s infinite linear;
    margin-bottom: 50px;
  }

  .movingupdown5:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown6 {
    display: none !important;
    margin-left: 20px;
    margin-bottom: 50px;
    width: 100px;
    height: 100px;
    border: 2px solid tomato;
    border-radius: 50%;
    background-color: #407eb5;
    color: white;
    animation: shake-mix 5s infinite linear;
    margin-bottom: 50px;
  }

  .movingupdown6:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .movingupdown7 {
    display: none !important;
    display: block;
    margin-left: 0px;
    margin-bottom: 50px;
    width: 100px;
    height: 100px;
    border: 2px solid tomato;
    border-radius: 50%;
    background-color: #6a53a2;
    color: white;
    animation: shake-mix 5s infinite linear;
    margin-bottom: 50px;
  }

  .movingupdown7:hover {
    display: none !important;
    animation: scale 6s linear infinite;
  }

  .gridforblub {
    display: grid;
    grid-template-columns: 1fr;
  }

  .circlepath {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  /* ======================================================= */
  .buttonh1as {
    padding-top: 85%;
    padding-left: 10%;
  }

  .greyas {
    margin-top: -20%;
    background-image: url(../images/greyb.png);
    background-size: cover;
    min-height: auto;
    width: 100%;
    padding-top: 40%;
  }

  .framerac {
    margin-left: 10%;
    width: 80vw;
    height: 80vh;
    margin-top: -50%;
    margin-bottom: -40%;
  }

  .nodisplay {
    display: none;
  }

  .landingcoverKV {
    background-image: url(../images/womanlanding.jpeg);
    background-size: cover;
    height: 100vh;
    margin-top: -10px;
  }

  .marketingcsasdj {
    display: grid;
    grid-template-columns: 1fr !important;
    margin-left: 10%;
    margin-right: 5%;
  }

  .h1hKV {
    color: white;
    font-weight: bold;
    position: absolute;
    top: 20%;
    margin-left: 5%;
    font-size: 20px !important;
  }
}