.gridfortalk {
  display: grid;

  grid-template-columns: 1fr 4fr 1fr;
}
.talkimage {
  height: 80px;
  width: 100px;
}
.middletalk {
  font-size: 30px;
}

@media (min-width: 600px) and (max-width: 1025px) {
  .gridfortalk {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
  }
  .middletalk {
    font-size: 25px;
  }
  .talkimage {
    height: 80px;
    width: 100px;
  }
}
@media (min-width: 400px) and (max-width: 600px) {
  .gridfortalk {
    display: grid;
    grid-template-columns: 1fr;
  }
  .middletalk {
    margin-top: 50px;
    font-size: 20px;
    margin-bottom: 50px;
  }
  .talkimage {
    margin-left: 30%;
    height: 60px;
    width: 80px;
  }
  .button-class {
    margin-left: 12%;
  }
}
@media (max-width: 400px) {
  .gridfortalk {
    display: none;
    grid-template-columns: 1fr;
  }
  .middletalk {
    text-align: center;
    margin-left: 20px;
    font-size: 20px;
  }
  .talkimage {
    margin-left: 30%;
    height: 60px;
    width: 80px;
  }
  .button-class {
    margin-left: 12%;
  }
}
