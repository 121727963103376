.wallpaper17as {
  background-image: url(../images/aboutblue.png);
  background-size: cover;
  height: 110vh;
  width: 100%;
}
.h4abc {
  font-size: 30px;
  font-weight: 600;
  text-align: left !important;
  position: relative;
  top: 30%;
  margin-left: 5%;
  margin-right: 50%;
  color: white;
}
.paatanii {
  margin-left: 5%;
  font-size: 70px;
  font-weight: 900;
  text-align: left;
  position: relative;
  top: 25%;
  color: white;
}
.gridforpartners {
  margin-left: 20%;

  margin-top: 10%;
  margin-bottom: 15%;
}
.partneraslogo {
  width: 100px;
  height: 100px;
  margin-top: 10%;
}
.abcxyz {
  padding-left: 2%;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.headimgcarddjasdjas {
  font-size: 22px;
  font-weight: 300;
  text-align: center;
  margin: 5% 15% 5% 15%;
  letter-spacing: 2px;
  color: rgba(128, 128, 128, 0.76);
}
.cardgridfortraget {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-left: 10%;
  margin-right: 10%;
}
.amrit {
  margin-top: -10%;
}
.maja {
  margin-top: -4%;
}
@media (min-width: 996px) and (max-width: 1280px) {
  .h4abc {
    font-size: 20px;
    font-weight: 600;
    text-align: left !important;
    position: relative;
    top: 30%;
    margin-left: 5%;
    margin-right: 30%;
    color: white;
  }
  .cardgridfortraget {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 10%;
    margin-right: 10%;
  }
  .wallpaper17as {
    background-image: url(../images/aboutblue.png);
    background-size: cover;
    height: 55vh;
    width: 100%;
  }
}
@media (min-width: 700px) and (max-width: 996px) {
  .h4abc {
    font-size: 20px;
    font-weight: 600;
    text-align: left !important;
    position: relative;
    top: 30%;
    margin-left: 5%;
    margin-right: 30%;
    color: white;
  }
  .cardgridfortraget {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 10%;
    margin-right: 10%;
  }
  .wallpaper17as {
    background-image: url(../images/aboutblue.png);
    background-size: cover;
    height: 55vh;
    width: 100%;
  }
}
@media (min-width: 400px) and (max-width: 700px) {
  .h4abc {
    font-size: 20px;
    font-weight: 600;
    text-align: left !important;
    position: relative;
    top: 30%;
    margin-left: 5%;
    margin-right: 5%;
    color: white;
  }
  .gridforpartners {
    margin-left: 10%;

    margin-top: 10%;
    margin-bottom: 15%;
  }
  .cardgridfortraget {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 10%;
    margin-right: 10%;
  }
  .wallpaper17as {
    background-image: url(../images/aboutblue.png);
    background-size: cover;
    height: 100vh;
    width: 100%;
  }
  .abcxyz {
    padding-left: 0%;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 400px) {
  .h4abc {
    font-size: 20px;
    font-weight: 600;
    text-align: left !important;
    position: relative;
    top: 30%;
    margin-left: 5%;
    margin-right: 5%;
    color: white;
  }
  .gridforpartners {
    margin-left: 5%;

    margin-top: 10%;
    margin-bottom: 15%;
  }
  .cardgridfortraget {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 10%;
    margin-right: 10%;
  }
  .wallpaper17as {
    background-image: url(../images/aboutblue.png);
    background-size: cover;
    height: 100vh;
    width: 100%;
  }
  .abcxyz {
    padding-left: 0%;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
