.wallpaper2as {
  background-image: url(../images/coverContentSyndication.png);
  background-size: cover;
  height: 105vh;
  width: 100%;
}
.headingwallpaper {
  position: relative;
  top: 40%;
}
.h1wallpaper {
  text-transform: uppercase;
  font-size: 50px;
  color: white;
  margin-left: 8%;
}
.h2wallpaper {
  font-size: 25px;
  font-weight: 600;
  color: white;
  margin-left: 8%;
}
.leftgrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 100px;
  margin-left: 150px;
  margin-right: 150px;
}
.lefthead {
  font-size: 40px;
  font-weight: 400;
  color: black;
}
.leftpara {
  margin-top: 50px;
  font-size: 18px;

  color: grey;
}
.imgrightas {
  margin-left: 50px;
  height: 50vh;
  width: 40vw;
}
.imgrightdj {
  height: 100%;
  width: 100%;
}
.intermediate {
  background-image: url(../images/contentbg_contentsyndication.png);
  background-size: cover;
  height: 140vh;
  width: 100%;
}
.intermediate {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.lefthead1 {
  margin-left: 150px;
  margin-right: 150px;
  margin-top: 50%;
  font-size: 40px;
  font-weight: 400;
  color: white;
}
.leftpara1 {
  margin-top: 50px;
  margin-left: 150px;
  margin-right: 150px;
  font-size: 16px;
  font-weight: 400;
  color: white;
}

.accountright {
  margin-top: 40%;
  margin-left: 50px;
  height: 60vh;
  width: 40vw;
}
.h1textaligncenter {
  margin-top: 100px;
  font-size: 40px;
  font-weight: 600;
  color: black;
  text-align: center;
}
.cardsgrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 100px;
  margin-left: 9%;
}
.talkdiv {
  margin: 100px;
}
.mar {
  margin-top: 10%;
}
@media (min-width: 996px) and (max-width: 1280px) {
  .lefthead1 {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 70%;
    font-size: 40px;
    font-weight: 400;
    color: white;
  }
  .leftpara1 {
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 50px;
    font-size: 16px;
    font-weight: 400;
    color: white;
  }

  .accountright {
    margin-top: 40%;
    margin-left: 50px;
    height: 40vh;
    width: 40vw;
  }
  .intermediate {
    background-image: url(../images/contentbg_contentsyndication.png);
    background-size: cover;
    height: auto;
    width: 100%;
  }
  .intermediate {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .imgrightas {
    margin-left: 50px;
    height: 30vh;
    width: 40vw;
  }
  .wallpaper2as {
    background-image: url(../images/coverContentSyndication.png);
    background-size: cover;
    height: 50vh;
    width: 100%;
  }
  .leftgrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 100px;
    margin-left: 50px;
    margin-right: 50px;
  }
  .cardsingle {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .cardsgrid {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 100px;
    margin-left: 9%;
  }
}
@media (min-width: 700px) and (max-width: 996px) {
  .talkdiv {
    margin: 50px;
  }
  .h1wallpaper {
    text-transform: uppercase;
    font-size: 30px;
    color: white;
    margin-left: 8%;
  }
  .lefthead1 {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 10%;
    font-size: 40px;
    font-weight: 400;
    color: white;
  }
  .leftpara1 {
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 50px;
    font-size: 16px;
    font-weight: 400;
    color: white;
  }

  .accountright {
    margin-top: 0%;
    margin-left: 50px;
    height: 40vh;
    width: 40vw;
  }
  .intermediate {
    background-image: none;
    background-color: #1f85de;
    background-size: cover;
    height: auto;
    width: 100%;
  }
  .intermediate {
    display: grid;
    grid-template-columns: 1fr;
  }
  .imgrightas {
    margin-top: 100px;
    height: 50vh;
    width: 60vw;
    margin-bottom: 100px;
  }
  .wallpaper2as {
    background-image: url(../images/coverContentSyndication.png);
    background-size: cover;
    height: 50vh;
    width: 100%;
  }
  .leftgrid {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 100px;
    margin-left: 50px;
    margin-right: 50px;
  }
  .cardsingle {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .cardsgrid {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 100px;
    margin-left: 9%;
  }
}
@media (min-width: 400px) and (max-width: 700px) {
  .talkdiv {
    margin: 25px;
  }
  .h1wallpaper {
    text-transform: uppercase;
    font-size: 30px;
    color: white;
    margin-left: 8%;
  }
  .lefthead1 {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 10%;
    font-size: 40px;
    font-weight: 400;
    color: white;
  }
  .leftpara1 {
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 50px;
    font-size: 16px;
    font-weight: 400;
    color: white;
  }

  .accountright {
    margin-top: 0%;
    margin-left: 50px;
    height: 50vh;
    width: 60vw;
  }
  .intermediate {
    background-image: none;
    background-color: #1f85de;
    background-size: cover;
    height: auto;
    width: 100%;
  }
  .intermediate {
    display: grid;
    grid-template-columns: 1fr;
  }
  .imgrightas {
    margin-top: 100px;
    height: 50vh;
    width: 60vw;
    margin-bottom: 100px;
  }
  .wallpaper2as {
    background-image: url(../images/coverContentSyndication.png);
    background-size: cover;
    height: 100vh;
    width: 100%;
  }
  .leftgrid {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 100px;
    margin-left: 50px;
    margin-right: 50px;
  }
  .cardsingle {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .cardsgrid {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 100px;
    margin-left: 9%;
  }
}
@media (max-width: 400px) {
  .h1wallpaper {
    text-transform: uppercase;
    font-size: 30px;
    color: white;
    margin-left: 8%;
  }
  .lefthead1 {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10%;
    font-size: 40px;
    font-weight: 400;
    color: white;
  }
  .leftpara1 {
    margin-top: 50px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 400;
    color: white;
  }

  .accountright {
    margin-top: 5%;
    margin-left: 0px;
    height: 50vh;
    width: 70vw;
  }
  .intermediate {
    background-image: none;
    background-color: #1f85de;
    background-size: cover;
    height: auto;
    width: 100%;
  }
  .intermediate {
    display: grid;
    grid-template-columns: 1fr;
  }
  .imgrightas {
    margin-top: 50px;
    height: 50vh;
    width: 60vw;
    margin-bottom: 100px;
  }
  .wallpaper2as {
    background-image: url(../images/coverContentSyndication.png);
    background-size: cover;
    height: 100vh;
    width: 100%;
  }
  .leftgrid {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 50px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .cardsingle {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .cardsgrid {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 50px;
    margin-left: 0%;
  }
}
