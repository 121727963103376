@keyframes shake-down {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes shake-mix {
  0% {
    transform: translate(0);
  }
  50% {
    transform: translate(-12px, 12px);
  }
  100% {
    transform: translate(0);
  }
}

@keyframes shake-up {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideshow {
  0% {
    left: 0;
  }

  100% {
    left: -1600px;
  }
}
.mainCarouselBiliKV {
  position: relative;
  overflow-x: hidden;
  min-height: 120vh;
}

#locationscarouselKV {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 2000px;
  animation: slideshow 50s linear infinite;
}

#locationscarouselKV:hover {
  animation-play-state: paused;
}
.rowscArouselKVBili {
  margin-top: 20px;
  display: flex;
  padding: 18px 0;
}

.first-rowcarouselKv-box:nth-child(n + 1) {
  animation: shake-down 10s infinite linear;
}

.first-rowcarouselKv-box:nth-child(3n + 2) {
  animation: shake-mix 10s infinite linear;
}

.first-rowcarouselKv-box:nth-child(3n) {
  animation: shake-up 10s infinite linear;
}

.second-row-box:nth-child(3n + 1) {
  animation: shake-up 10s infinite linear;
}

.second-row-box:nth-child(3n + 2) {
  animation: shake-down 10s infinite linear;
}

.second-row-box:nth-child(3n) {
  animation: shake-mix 10s infinite linear;
}

.third-row-box:nth-child(3n + 1) {
  animation: shake-mix 10s infinite linear;
}

.third-row-box:nth-child(3n + 2) {
  animation: shake-up 10s infinite linear;
}

.third-row-box:nth-child(3n) {
  animation: shake-down 10s infinite linear;
}

.boxCarouselbILIkv {
  width: 200px;
  height: 200px;
  padding: 20px;
  margin-right: 160px;
  background-color: #ffffff;
  border-radius: 50%;
  display: grid;
  box-shadow: -3px 0 24px 0 rgba(0, 0, 0, 0.08);
}

.first-rowcarouselKV-box {
  margin: 60px;
}

.second-row-box {
  margin-right: 125px;
}

.third-row-box {
  margin-right: 160px;
}

.logoCAROUSELKV {
  max-width: 135px;
  margin: auto;
}

.companyLogoInCarouselKV {
  width: 100%;
  height: auto;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.2);
  }
}

.class-study {
  border-radius: 50%;
  transition: transform 2s linear;
}
/* 
.class-study:hover {
  border: none;
  transform: scale(1.2);
  animation: scale 16s infinite ease-in-out;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23EC3463' stroke-width='4' stroke-dasharray='2%2c 32%2c 2' stroke-dashoffset='4' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 100px;
} */

@media (min-width: 600px) and (max-width: 1025px) {
  .mainCarouselBiliKV {
    position: relative;
    overflow-x: hidden;
    min-height: 100vh;
    margin-bottom: -200px;
  }
}
@media (max-width: 600px) {
  .boxCarouselbILIkv {
    width: 100px;
    height: 100px;
    padding: 20px;
    margin-right: 100px;
    background-color: #ffffff;
    border-radius: 50%;
    display: grid;
    box-shadow: -3px 0 24px 0 rgba(0, 0, 0, 0.08);
  }
  .second-row-box {
    margin-right: 65px;
  }

  .third-row-box {
    margin-right: 100px;
  }
  .mainCarouselBiliKV {
    position: relative;
    overflow-x: hidden;
    min-height: 100vh;
  }
}
