.bofas {
  background-image: url(../images/INTEGRATED.png);
  background-size: cover;
  width: 100%;
  height: 110vh;
  color: white;
  margin-bottom: 5%;
}
.h2bofas {
  position: relative;
  top: 30%;
  margin-left: 10%;
}
.h1bofas {
  position: relative;
  top: 30%;
  margin-left: 10%;
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 30px;
}
.rowfordiv {
  display: grid;
  grid-template-rows: 1fr 1fr;
  margin: 3%;
}
.tickboxas {
  display: inline-flex !important;
}
.containerbofas {
  margin-left: 10%;
  margin-right: 10%;
}
.h1bofpart1as {
  text-align: center;
  position: relative;
  /* margin-top: 10%; */
  font-size: 40px;
  font-weight: 900;
}
.parabofas {
  font-weight: 400;
  text-align: center;
  color: black;
  margin-top: 30px;
  font-size: 20px;
  margin-bottom: 5%;
}
.part2bofas {
  background-image: url(../images/optimize.png);
  background-size: cover;
  width: 100%;
  height: 115vh;
  color: white;
  margin-bottom: 5%;
}
.gridpart3bofas {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 5%;
  padding-top: 10%;
  color: #000;
  margin-bottom: 5.5%;
}
.parapart2bofas {
  margin-bottom: 5%;
}

.tick {
  width: 50px;
  height: 50px;
  margin-right: 20px;
  margin-bottom: 5%;
}
.h5tickas {
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  color: black;
}
.h7bofas {
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  color: black;
  margin-bottom: 5%;
}
.h1bofdjas {
  text-align: center;
  font-size: 45px;
  font-weight: 700;
  color: #000;
}
.Numbergridas {
  margin-top: 5%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.h1forpart1as {
  font-size: 35px;
  margin-bottom: 5%;
}
.h1h7as {
  text-align: center;
  color: #000;
}
.lastcontaineras {
  background-image: url(../images/OWNER.png);
  background-size: cover;
  width: 100%;
  height: 40%;
  padding-top: 100px;
  margin-bottom: 5%;
  margin-top: 10%;
}
.contentbofpart1as {
  color: #000;

  /* margin-top: 120px;
    padding-bottom: 120px; */

  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  padding-top: 15%;
}
.imgcnbofasdj {
  height: 80px;
  width: 100px;
}
.bofh22as {
  font-size: 30px;

  margin-right: 5%;
}
.coloras {
  color: #000;
}
@media (min-width: 600px) and (max-width: 1026px) {
  .containerbofas {
    margin-left: 3%;
    margin-right: 3%;
  }
  .Numbergridas {
    margin-top: 5%;
    margin-left: -3%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 600px) and (max-width: 992px) {
  .gridpart3bofas {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 5%;
    padding-top: 10%;
    color: #000;
    margin-bottom: 5.5%;
  }
  .part2bofas {
    width: 100%;
    min-height: 115vh;
  }
  .containerbofas {
    margin-left: 2%;
    margin-right: 2%;
  }
  .bofh22as {
    font-size: 25px;

    margin-right: 5%;
  }
  .h1forpart1as {
    font-size: 30px;
    margin-bottom: 5%;
  }
  .Numbergridas {
    margin-top: 5%;

    display: grid;
    grid-template-columns: 1fr;
  }
}
@media (min-width: 400px) and (max-width: 600px) {
  .gridpart3bofas {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 5%;
    padding-top: 10%;
    color: #000;
    margin-bottom: 5.5%;
  }
  .h2bofas {
    position: relative;
    top: 30%;
    margin-left: 10%;
  }
  .h1bofas {
    position: relative;
    top: 30%;
    margin-left: 10%;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  .Numbergridas {
    margin-top: 5%;
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 3%;
  }
  .containerbofas {
    margin-left: 2%;
    margin-right: 2%;
  }
  .bofh22as {
    font-size: 20px;

    margin-right: 5%;
  }
  .contentbofpart1as {
    color: #000;

    /* margin-top: 120px;
      padding-bottom: 120px; */

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 15%;
  }
  .tick {
    width: 50px;
    height: 50px;
    padding-right: 10px;
    margin-right: 0;
    margin-bottom: 5%;
  }
  .h5tickas {
    font-size: 12px;
    font-weight: 600;
    display: inline-block;
    color: black;
  }
  .h7bofas {
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    color: black;
    margin-bottom: 5%;
  }
}
@media (max-width: 400px) {
  .h2bofas {
    position: relative;
    top: 30%;
    margin-left: 10%;
  }
  .h1bofas {
    position: relative;
    top: 30%;
    margin-left: 10%;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  .gridpart3bofas {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 5%;
    padding-top: 10%;
    color: #000;
    margin-bottom: 5.5%;
  }
  .Numbergridas {
    margin-top: 5%;
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 3%;
  }
  .containerbofas {
    margin-left: 2%;
    margin-right: 2%;
  }
  .bofh22as {
    font-size: 20px;

    margin-right: 5%;
  }
  .contentbofpart1as {
    color: #000;

    /* margin-top: 120px;
      padding-bottom: 120px; */

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 15%;
  }
  .tick {
    width: 50px;
    height: 50px;
    padding-right: 10px;
    margin-right: 0;
    margin-bottom: 5%;
  }
  .h5tickas {
    font-size: 12px;
    font-weight: 600;
    display: inline-block;
    color: black;
  }
  .h7bofas {
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    color: black;
    margin-bottom: 5%;
  }
}
