* {
  margin: 0;
  padding: 0;
}
.inmarketas {
  background-image: url(../images/wall.png);
  background-size: cover;
  width: 100%;
  height: 105vh;
  color: white;
}
.h2imlas {
  position: relative;
  top: 30%;
  margin-left: 10%;
}
.h5imlas {
  font-size: 20px;
  position: relative;
  top: 30%;
  margin-left: 10%;
}
.h1imlas {
  position: relative;
  top: 30%;
  margin-left: 10%;
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 30px;
}
.part2imlgridas {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* margin-bottom: 100px; */
}
.imgimlpart1as {
  margin-right: 50px;
  width: 40vw;
  height: 50vh;
}
.containerimlas {
  margin-left: 10%;
  margin-right: 10%;
}
.h1imlpart1as {
  text-align: center;
  position: relative;
  /* margin-top: 10%; */
  font-size: 35px;
}
.gridpart2imlas {
  margin-right: 5%;
}
.paraimlas {
  font-weight: 400;
  text-align: center;
  color: gray;
  margin-top: 30px;
  font-size: 16px;
  margin-bottom: 40px;
}
.identityas {
  background-image: url(../images/indentify.png);
  background-size: cover;
  height: 1000px;
  width: 100%;
}
.gridforimlas {
  display: grid;

  grid-template-columns: 1fr 1fr;
}

.gridpart3imlas {
  margin-top: 60%;
  margin-right: 100px;
  color: white;
  text-align: center;
}
.parapart2imlas {
  margin-bottom: 30px;
  margin-top: 30px;
}
.h1abmpart1as {
  margin-top: 50px;
  margin-bottom: 50px;
}
.forgridas {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.imlh22as {
  font-size: 30px;
}
.gridimlforas {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  margin-bottom: 100px;
}
.imlgridforas {
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin-bottom: 50px;
}
.imgsrcas {
  width: 90%;
  height: 100%;
}
.h1forgridas {
  font-size: 18px;
  color: gold;
}
.parapart3imlas {
  font-size: 24px;
}
@media (min-width: 996px) and (max-width: 1280px) {
  .felxdj {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .identityas {
    background-image: url(../images/indentify.png);
    background-size: cover;
    height: 800px;
    width: 100%;
  }
  .gridpart3imlas {
    margin-top: 50%;
    margin-left: 5%;
    margin-right: 5%;
    color: white;
    text-align: center;
  }
  .inmarketas {
    background-image: url(../images/wall.png);
    background-size: cover;
    width: 100%;
    height: 50vh;
    color: white;
  }
  .containerimlas {
    margin-left: 0%;
    margin-right: 0%;
  }
  .imgimlpart1as {
    margin-right: 10px;
    width: 40vw;
    height: 40vh;
    margin-left: 20px;
  }
  .forgridas {
    display: grid;
    grid-template-columns: 1fr;
  }
}
@media (min-width: 700px) and (max-width: 996px) {
  .inmarketas {
    background-image: url(../images/wall.png);
    background-size: cover;
    width: 100%;
    height: 60vh;
    color: white;
  }

  .gridforimlas {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .felxdj {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .gridpart3imlas {
    margin-top: 60%;
    color: white;
    text-align: center;
  }
  .forgridas {
    display: grid;
    grid-template-columns: 1fr;
  }
  .gridimlforas {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    margin-bottom: 50px;
  }
  .imgsrcas {
    margin-bottom: 50px;
  }
  .identityas {
    background-image: url(../images/indentify.png);
    background-size: cover;
    height: 1000px;
    width: 100%;
  }
  .imgimlpart1as {
    margin-right: 10px;
    width: 40vw;
    height: 40vh;
    margin-left: 20px;
  }
  .containerimlas {
    margin-left: 5%;
    margin-right: 5%;
  }
}
@media (min-width: 400px) and (max-width: 700px) {
  .gridforimlas {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .felxdj {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .part2imlgridas {
    display: grid;
    grid-template-columns: 1fr;
    /* margin-bottom: 100px; */
  }

  .gridimlforas {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    margin-bottom: 50px;
  }
  .imgsrcas {
    margin-bottom: 50px;
  }
  .gridpart3imlas {
    margin-top: 100%;
    margin-right: 0;
    color: white;
    text-align: center;
  }
  .forgridas {
    display: grid;
    grid-template-columns: 1fr;
  }
  .containerimlas {
    margin-left: 0%;
    margin-right: 0%;
  }
  .imgimlpart1as {
    margin-right: 10px;
    width: 60vw;
    height: 40vh;
    margin-left: 20px;
  }
  .gridpart1imlas {
    display: flex;
    justify-content: center;
    align-content: center;
  }
}
@media (max-width: 400px) {
  .gridforimlas {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .felxdj {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .part2imlgridas {
    display: grid;
    grid-template-columns: 1fr;
    /* margin-bottom: 100px; */
  }

  .gridimlforas {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    margin-bottom: 50px;
  }
  .imgsrcas {
    margin-bottom: 50px;
  }
  .gridpart3imlas {
    margin-right: 0;
    margin-top: 100%;
    color: white;
    text-align: center;
  }
  .forgridas {
    display: grid;
    grid-template-columns: 1fr;
  }
  .containerimlas {
    margin-left: 0%;
    margin-right: 0%;
  }
  .imgimlpart1as {
    margin-right: 10px;
    width: 60vw;
    height: 40vh;
    margin-left: 20px;
  }
  .gridpart1imlas {
    display: flex;
    justify-content: center;
    align-content: center;
  }
}
