.wallpaper6as {
  background-image: url(../images/backgr.png);
  background-size: cover;
  height: 105vh;
  width: 100%;
}
.margintopddaa {
  margin-top: 10%;
}
.paradetails1 {
  font-weight: 400;
  text-align: left;
  color: gray;
  margin-top: 30px;
  font-size: 16px;
  margin-bottom: 40px;
}
.intermediate2 {
  background-image: url(../images/interme.png);
  background-size: cover;
  height: 110vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.lefthead2 {
  margin-left: 150px;
  margin-right: 150px;
  margin-top: 30%;
  font-size: 40px;
  font-weight: 400;
  color: white;
}
.leftpara2 {
  margin-top: 10px;
  margin-left: 150px;
  margin-right: 150px;
  font-size: 16px;
  font-weight: 400;
  color: white;
}
.imggraphics {
  width: 100%;
}
.imggraphics1 {
  width: 80vw;
  margin-left: 10%;
}
.margintopddaaa {
  margin-top: 10%;
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 10%;
}

@media (min-width: 996px) and (max-width: 1280px) {
  .wallpaper6as {
    background-image: url(../images/backgr.png);
    background-size: cover;
    height: 50vh;

    width: 100%;
  }
  .flexdjas {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .intermediate2 {
    background-image: url(../images/interme.png);
    background-size: cover;
    height: 80vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }
  .lefthead2 {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 40%;
    font-size: 50px;
    font-weight: 400;
    color: white;
  }
  .leftpara2 {
    margin-top: 20px;
    margin-left: 10%;
    margin-right: 10%;
    font-size: 25px;
    font-weight: 400;
    color: white;
  }
}
@media (min-width: 700px) and (max-width: 996px) {
  .wallpaper6as {
    background-image: url(../images/backgr.png);
    background-size: cover;
    height: 50vh;

    width: 100%;
  }
  .flexdjas {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .intermediate2 {
    background-image: url(../images/interme.png);
    background-size: cover;
    height: 80vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }
  .lefthead2 {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 35%;
    font-size: 40px;
    font-weight: 400;
    color: white;
  }
  .leftpara2 {
    margin-top: 20px;
    margin-left: 10%;
    margin-right: 10%;
    font-size: 16px;
    font-weight: 400;
    color: white;
  }
}
@media (min-width: 400px) and (max-width: 700px) {
  .wallpaper6as {
    background-image: url(../images/backgr.png);
    background-size: cover;
    height: 100vh;

    width: 100%;
  }
  .flexdjas {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .intermediate2 {
    background-image: url(../images/interme.png);
    background-size: cover;
    height: 90vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 5%;
  }
  .lefthead2 {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 45%;
    font-size: 30px;
    font-weight: 400;
    color: white;
  }
  .leftpara2 {
    margin-top: 20px;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 16px;
    font-weight: 400;
    color: white;
  }
}
@media (max-width: 400px) {
  .wallpaper6as {
    background-image: url(../images/backgr.png);
    background-size: cover;
    height: 100vh;

    width: 100%;
  }
  .flexdjas {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .intermediate2 {
    background-image: url(../images/interme.png);
    background-size: cover;
    height: 110vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 5%;
  }
  .lefthead2 {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 55%;
    font-size: 30px;
    font-weight: 400;
    color: white;
  }
  .leftpara2 {
    margin-top: 20px;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 16px;
    font-weight: 400;
    color: white;
  }
  .margintopddaa {
    margin-top: 0% !important;
  }
}
