.buttonasdjas {
  display: block;
  border: #32c24f solid 2px;
  width: 190px;
  border-radius: 100px;
  height: 45px;
  background-color: #32c24f;
  color: #ffffff;
  box-shadow: rgba(245, 245, 245, 1);
  margin-left: 10px;
  outline: none !important;
  cursor: pointer !important;
}
.buttonasdjas:hover {
  background-color: #ffffff;
  color: #32c24f;
  transition: ease-in all 0.4s;
  font-weight: bold;
  /* box-shadow: 5px -5px 10px rgba(245, 245, 245, 1); */
  font-size: medium;
  outline: none;
}
@media (min-width: 600px) and (max-width: 992px) {
  .buttonasdjas {
    display: block;
    border: #32c24f solid 2px;
    width: 150px;
    border-radius: 100px;
    height: 55px;
    background-color: #32c24f;
    color: #ffffff;

    margin-left: 10px;
  }
}
@media (max-width: 600px) {
  .buttonasdjas {
    display: block;
    border: #32c24f solid 2px;
    width: 100px;
    border-radius: 100px;
    height: 40px;
    background-color: #32c24f;
    color: #ffffff;
    font-size: 12px;
    margin-left: 10px;
  }
}
