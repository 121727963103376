html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  background: white;
  font-family: "Gotham" sans-serif;
}

.footeras {
  background: rgb(49, 49, 49);
  color: #ffffff;
  height: 600px;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer-content {
  padding: 70px;
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr;
  width: auto;
}

.linkfas {
  color: #ffffffc7;
  text-decoration: none !important;
}

.linkfas:hover {
  color: #ffffff;
  font-weight: 500;
}

.logofas {
  margin-right: 10%;
}

.marginboott {
  margin-bottom: 15%;
}

.knowusfas {
  margin-left: 5%;
}

.imgfas {
  width: 150px;
  height: 60px;
}

.dj {
  padding-top: 10px;
  padding-right: 18px;
  font-size: 18px;
}

.dj:hover {
  box-shadow: rgba(255, 255, 255, 0.8);
  color: #f6f6f6d5;
}

.iconsfas {
  margin-left: 45px;
  cursor: pointer;
}

/* .privacyfas {
  margin-right: 50%;
} */

.h3fas {
  margin-top: 18px;
  font-size: 16px;
}

.h4fas {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 200;
}

.h4fas2 {
  font-size: 14px;
  font-weight: 100;
}

.parafas {
  font-size: 14px;
  margin: 5px;
  font-weight: 100;
}

.inputas {
  margin-top: 20px;
  border: none;

  color: white;
  background-color: transparent;
  margin-bottom: 25px;
}

.inputas:focus {
  outline: none;
}

.buttonstylebc {
  margin-top: 20px;
  margin-bottom: 50px;
  margin-left: 4%;
  width: 200px;
  height: 40px;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  background-color: rgb(169, 5, 5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonstylebc:hover {
  color: #fff;
  background-color: transparent;
  font-weight: 700;
}

.meraman {
  background-color: #ddd;
  margin-bottom: 10px;

  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.subscribefas {
  padding-left: 20px;
}

.footerbottomas {
  background: rgb(29, 28, 28);
  height: 50px;
  z-index: 20;
  margin-top: -2.5%;
  text-align: center;
  padding-top: 10px;
}

.linkfasb {
  color: #ffffffc7;
  text-decoration: none !important;
  font-weight: bold;
}

.linkfasb:hover {
  color: #ffffff;
}

.felx {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.paddingfas {
  display: flex;
  justify-content: center;
  align-content: center;
}

.djas {
  color: #fff;
}

.djhbc {
  margin-right: 90px;
}

@media (min-width: 600px) and (max-width: 992px) {
  .footeras {
    bottom: 0;
    background: rgb(49, 49, 49);
    color: #ffffff;
    height: auto;
    position: relative;
    width: 100%;
  }

  .logofas {
    margin: 10px;
  }

  .footer-content {
    padding: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .subscribefas {
    padding-left: 5px;
  }

  .paddingfas {
    display: flex;
  }
}

@media (min-width: 400px) and (max-width: 600px) {
  .footeras {
    bottom: 0;
    background: rgb(49, 49, 49);
    color: #ffffff;
    height: auto;
    position: relative;
    width: 100%;
  }

  .logofas {
    margin: 5px;
  }

  .footer-content {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .subscribefas {
    padding-left: 5px;
  }

  .paddingfas {
    display: flex;
  }
}

@media (max-width: 400px) {
  .footeras {
    bottom: 0;
    background: rgb(49, 49, 49);
    color: #ffffff;
    height: auto;
    position: relative;
    width: 100%;
  }

  .logofas {
    margin: 0;
  }

  .footer-content {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .subscribefas {
    padding-left: 5px;
  }

  .footerbottomas {
    margin-top: 5px;
    margin-left: 30px;
  }

  .paddingfas {
    display: flex;
  }

  .djas {
    padding-top: 25px;
    padding-left: 10px;
    padding-right: 0px;
    color: #ffa800;
  }
}
