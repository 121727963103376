.buttonas {
  display: block;
  border: #ffa800 solid 2px;
  width: 190px;
  border-radius: 100px;
  height: 45px;
  background-color: #ffa800;
  color: #ffffff;
  box-shadow: rgba(245, 245, 245, 1);
  margin-left: 10px;
  outline: none !important;
  cursor: pointer;
}
.buttonas:hover {
  background-color: #ffffff;

  color: #ffa800;
  transition: ease-in all 0.4s;
  font-weight: bold;
  /* box-shadow: 5px -5px 10px rgba(245, 245, 245, 1); */
  font-size: medium;
  outline: none;
}
@media (min-width: 996px) and (max-width: 1280px) {
  .buttonas {
    display: block;
    border: #ffa800 solid 2px;
    width: 190px;
    border-radius: 100px;
    height: 45px;
    background-color: #ffa800;
    color: #ffffff;
    box-shadow: rgba(245, 245, 245, 1);
    margin-left: 10px;
    outline: none !important;
    cursor: pointer;
  }
  .buttonas:hover {
    background-color: #ffffff;

    color: #ffa800;
    transition: ease-in all 0.4s;
    font-weight: bold;
    /* box-shadow: 5px -5px 10px rgba(245, 245, 245, 1); */
    font-size: medium;
    outline: none;
  }
}
@media (min-width: 600px) and (max-width: 992px) {
  .buttonas {
    display: block;
    border: #ffa800 solid 2px;
    width: 150px;
    border-radius: 100px;
    height: 55px;
    background-color: #ffa800;
    color: #ffffff;

    margin-left: 10px;
  }
}
@media (max-width: 600px) {
  .buttonas {
    display: block;
    border: #ffa800 solid 2px;
    width: 100px;
    border-radius: 100px;
    height: 40px;
    background-color: #ffa800;
    color: #ffffff;
    font-size: 12px;
    margin-left: 10px;
  }
}
