.backgroundimagecontact {


  height: auto;
  width: 100%;
  position: relative;
  top: 150px;
  margin-bottom: 20%;

}

.rowgrid {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.leadgeneratefas {
  padding-right: 7%;
}

.chica {
  display: flex;
  justify-content: center;
  align-content: center;
  color: #ffa800;
}

.calfor {
  display: flex;
  justify-content: center;
  align-content: center;
  color: #1f85de;
}

.banga {
  display: flex;
  justify-content: center;
  align-content: center;
  color: #32c24f;
}

.clockcssjs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.bcimage {
  background-image: url("../images/llp.svg");
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  width: 350px;
  right: 0;
  top: 0;
  height: 300px;




}

.firstback {


  display: flex;
  justify-content: center;
  align-content: center;
}

.imgcontact {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 800px;
  width: 800px;
}

.h1asdj {
  color: #1f85de;
  font-size: 30px !important;
  padding-top: 5%;
  text-align: center;
}

.h2asdj {
  color: #000000;
  font-size: 60px !important;

  text-align: center;
}

.gridname {
  display: grid;
  grid-template-columns: 1fr 1fr;

}

.aaac::placeholder {
  font-size: 14px;
  color: gray;
}

.aaac {
  flex: 1;
  padding: 18px 40px;
  font-size: 16px;
  color: #000;
  background: transparent;
  border: none;
  border-bottom: #000 solid 2px;
  font-weight: 700;
  outline: none;
  padding-left: 5px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.asdfgh {
  height: 50px;
  width: 50px;
  margin-top: 10%;
}

#captcha {
  height: 30px;
  width: 30px;

  margin: 20px;
}

.buteded {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  margin-top: 5%;


  margin-bottom: 30px;

  background-color: rgba(95, 158, 160, 0.356);
}

.faru {
  width: 50%;
}

.buttons {
  width: 200px;
  height: 50px;
  color: white;
  font-size: 25px;
  border-radius: 5px;
  background: linear-gradient(to right,
      rgba(0, 153, 213, 0.856),
      rgba(53, 229, 176, 0.425));
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons:hover {
  background: linear-gradient(to right,
      rgba(53, 229, 176, 0.425),
      rgba(0, 153, 213, 0.856));
  color: black;
  background-color: white;
  font-weight: 700;
}

.contactdivmargin {

  margin-bottom: 10%;
  margin-left: 30%;
  margin-right: 30%;
}

.aass {
  padding-left: 2%;
}

.manti {
  padding-left: 10px;
}

.buteeed {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-top: 5%;
}

.butuonasdj {
  display: flex;
  justify-content: center;
  align-items: center;
}

.marketingcsasdj {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.label {
  color: #534d4d;
  margin-left: 2%;
}

.butcheck {
  margin-top: 3%;
}

@media (min-width: 996px) and (max-width: 1280px) {

  .clockcssjs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .imgcontact {
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 600px;
    width: 600px;
  }

  /* 
  .backgroundimagecontact {
    background-size: cover;
    height: auto !important;
    width: 100%;
    position: relative;
    top: 150px;
    margin-bottom: 50%;
    margin-right: 5% !important;
  } */

  .buteeed {
    margin-top: 0%;
  }

  .buteded {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    margin-top: 2%;
    margin-left: 4%;
    margin-bottom: 10px;
    margin-right: 4%;
    background-color: rgba(95, 158, 160, 0.356);
  }

  .butuonasdj {
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .contactdivmargin {
    margin-left: 5%;
    margin-right: 5%;

  }
}

@media (min-width: 700px) and (max-width: 996px) {
  .faru {
    width: 50%;
  }

  .aaac::placeholder {
    font-size: 14px;
  }


  .clockcssjs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .imgcontact {
    margin-top: 0% !important;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 700px;
  }

  .backgroundimagecontact {
    background-image: none;
    height: 0;
    background-size: cover;
    height: auto !important;
    width: 100%;
    position: relative;
    top: 100px;
    margin-bottom: 10% !important;
    margin-right: 0%;
  }

  .buteeed {
    margin-top: 0%;
  }

  .buteded {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    margin-top: 2%;
    margin-left: 4%;
    margin-bottom: 10px;
    margin-right: 4%;
    background-color: rgba(95, 158, 160, 0.356);
  }

  .butuonasdj {
    display: flex;
    justify-content: center;
    align-items: center;
  }



  .contactdivmargin {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media (min-width: 400px) and (max-width: 700px) {
  .aaac::placeholder {
    font-size: 12px;
  }

  .faru {
    width: 70%;
  }

  .clockcssjs {
    display: grid;
    grid-template-columns: 1fr;
  }

  .imgcontact {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 400px;
    width: 400px;
  }

  .backgroundimagecontact {
    background-image: none;
    background-size: cover;
    height: auto !important;
    width: 100%;
    position: relative;
    top: 100px;
    margin-bottom: 50%;
    margin-right: 5%;
  }

  .buteeed {
    margin-top: 0%;
  }

  .buteded {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    margin-top: 2%;
    margin-left: 4%;
    margin-bottom: 10px;
    margin-right: 4%;
    background-color: rgba(95, 158, 160, 0.356);
  }

  .butuonasdj {
    display: flex;
    justify-content: center;
    align-items: center;
  }



  .contactdivmargin {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media (max-width: 400px) {
  .faru {
    width: 90%;
  }

  .clockcssjs {
    display: grid;
    grid-template-columns: 1fr;
  }

  .imgcontact {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 300px;
    width: 300px;
  }

  .backgroundimagecontact {
    background-image: none;
    background-size: cover;
    height: auto !important;
    width: 100%;
    position: relative;
    top: 150px;
    margin-bottom: 50%;
    margin-right: 0%;
  }

  .buteeed {
    margin-top: 0%;
  }

  .buteded {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    margin-top: 2%;
    margin-left: 4%;
    margin-bottom: 10px;
    margin-right: 4%;
    background-color: rgba(95, 158, 160, 0.356);
  }

  .butuonasdj {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aaac::placeholder {
    font-size: 12px;
  }

  .contactdivmargin {
    margin-left: 5%;
    margin-right: 5%;
  }
}