.buttonasdj {
  display: block;
  border: #1f85de solid 2px;
  width: 190px;
  border-radius: 100px;
  height: 45px;
  background-color: #1f85de;
  color: #ffffff;
  box-shadow: rgba(245, 245, 245, 1);
  margin-left: 10px;
  outline: none !important;
  cursor: pointer;
  text-decoration-line: none;
}
.buttonasdj:hover {
  text-decoration: none;
  background-color: #ffffff;
  color: #1f85de;
  transition: ease-in all 0.4s;
  font-weight: bold;
  /* box-shadow: 5px -5px 10px rgba(245, 245, 245, 1); */
  font-size: medium;
  outline: none;
}
@media (min-width: 600px) and (max-width: 992px) {
  .buttonasdj {
    display: block;
    border: #1f85de solid 2px;
    width: 150px;
    border-radius: 100px;
    height: 55px;
    background-color: #1f85de;
    color: #ffffff;

    margin-left: 10px;
  }
}
@media (max-width: 600px) {
  .buttonasdj {
    display: block;
    border: #1f85de solid 2px;
    width: 100px !important;
    border-radius: 100px;
    height: 40px !important;
    background-color: #1f85de;
    color: #ffffff;
    font-size: 12px !important;
    margin-left: 10px;
  }
}
