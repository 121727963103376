.divabmas {
  background-image: url(../images/accountmain.png);
  background-size: cover;
  height: 110vh;
}
.headabmas {
  position: relative;
  top: 30%;
  margin-left: 9%;
}
.h2abmas {
  font-size: 30px;
  letter-spacing: 2px;
  color: white;
}
.h1abmas {
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 2px;
  color: white;
}
.abmpart2containeras {
  position: relative;

  margin-left: 8%;
  margin-right: 8%;
}
.containerabmas {
  position: relative;
  top: 75%;
  margin-left: 8%;
  margin-right: 8%;
}
.gridabmas {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.h1abmpart1as {
  text-align: center;
  position: relative;
  /* margin-top: 10%; */
  font-size: 35px;
}
.imgcnabmas {
  width: 40vw;
  height: 60vh;
}
.paraabmas {
  font-weight: 400;
  text-align: center;
  color: gray;
  margin-top: 30px;
  font-size: 20px;
  margin-bottom: 40px;
}
.divincreaseabmas {
  background-image: url(../images/roi.png);
  background-size: cover;

  position: relative;
  margin-top: 50%;
  height: 112%;
  width: 100%;
}
.h1abmpart3as {
  text-align: center;
  position: relative;
  margin-top: 5%;
  margin-bottom: 100px;
  font-size: 40px;
  font-weight: 400;
  color: black;
}
.h1abmpart2as {
  position: relative;
  color: white;

  /* margin-top: 10%; */
  font-size: 35px;
  padding-top: 16%;

  margin-left: 100px;
}
.paraabmpart2as {
  position: relative;
  color: white;
  margin-left: 100px;
}
.paraabmpart2asdj {
  margin-top: 40px;
}
.cardgridas {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.contentabmpart1as {
  margin-top: 120px;
  padding-bottom: 120px;

  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
}
.imgcnabmasdj {
  height: 80px;
  width: 100px;
}
.abmh22as {
  font-size: 40px;
}
@media (min-width: 996px) and (max-width: 1280px) {
  .divincreaseabmas {
    background-image: url(../images/roi.png);
    background-size: cover;

    position: relative;
    margin-top: 60%;
    height: auto;
    padding-bottom: 10%;
    width: 100%;
  }
  .cardgridas {
    display: grid;
    grid-template-columns: 1fr;
  }
  .imgcnabmas {
    width: 40vw;
    height: 30vh;
  }
  .divabmas {
    background-image: url(../images/accountmain.png);
    background-size: cover;
    height: 50vh;
  }
  .abmpart2containeras {
    position: relative;

    margin-left: 0%;
    margin-right: 0%;
  }
}
@media (min-width: 700px) and (max-width: 996px) {
  .h2abmas {
    font-size: 20px;
    letter-spacing: 2px;
    color: white;
  }
  .h1abmas {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 2px;
    color: white;
  }
  .containerabmas {
    position: relative;
    top: 75%;
    margin-left: 3%;
    margin-right: 3%;
  }
  .divincreaseabmas {
    background-image: url(../images/roi.png);
    background-size: cover;

    position: relative;
    margin-top: 60%;
    height: auto;
    padding-bottom: 10%;
    width: 100%;
  }
  .cardgridas {
    display: grid;
    grid-template-columns: 1fr;
  }
  .imgcnabmas {
    width: 40vw;
    height: 30vh;
  }
  .divabmas {
    background-image: url(../images/accountmain.png);
    background-size: cover;
    height: 50vh;
  }
  .abmpart2containeras {
    position: relative;

    margin-left: 3%;
    margin-right: 3%;
  }
}
@media (min-width: 400px) and (max-width: 700px) {
  .contentabmpart1as {
    margin-top: 120px;
    padding-bottom: 120px;

    display: grid;
    grid-template-columns: 1fr;
  }
  .paraabmpart2as {
    position: relative;
    color: white;
    margin-left: 10px;
    font-size: 16px;
  }
  .h1abmpart2as {
    position: relative;
    color: white;

    /* margin-top: 10%; */
    font-size: 25px;
    padding-top: 16%;

    margin-left: 10px;
  }
  .contentabmas {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .gridabmas {
    display: grid;
    grid-template-columns: 1fr;
  }
  .h2abmas {
    font-size: 20px;
    letter-spacing: 2px;
    color: white;
  }
  .imgcnabmasdj {
    margin-left: 40%;
    height: 80px;
    width: 100px;
  }
  .h1abmas {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 2px;
    color: white;
  }
  .containerabmas {
    position: relative;
    top: 75%;
    margin-left: 3%;
    margin-right: 3%;
  }
  .divincreaseabmas {
    background-image: url(../images/roi.png);
    background-size: cover;

    position: relative;
    margin-top: 500px;
    height: 760px;
    padding-bottom: 0%;
    padding-top: 15%;
    width: 100%;
  }
  .cardgridas {
    display: grid;
    grid-template-columns: 1fr;
  }
  .imgcnabmas {
    width: 60vw;
    height: 50vh;
    margin-bottom: 10%;
  }
  .divabmas {
    background-image: url(../images/accountmain.png);
    background-size: cover;
    height: 100vh;
  }
  .abmpart2containeras {
    position: relative;

    margin-left: 3%;
    margin-right: 3%;
  }
}
@media (max-width: 400px) {
  .abmh22as {
    font-size: 40px;
    margin-left: 15%;
  }
  .imgcnabmasdj {
    margin-left: 30%;
    height: 80px;
    width: 100px;
  }
  .contentabmpart1as {
    margin-top: 120px;
    padding-bottom: 120px;

    display: grid;
    grid-template-columns: 1fr;
  }

  .paraabmpart2as {
    position: relative;
    color: white;
    margin-left: 10px;
    font-size: 16px;
  }
  .h1abmpart2as {
    position: relative;
    color: white;

    /* margin-top: 10%; */
    font-size: 25px;
    padding-top: 16%;

    margin-left: 10px;
  }
  .contentabmas {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .gridabmas {
    display: grid;
    grid-template-columns: 1fr;
  }
  .h2abmas {
    font-size: 20px;
    letter-spacing: 2px;
    color: white;
  }
  .h1abmas {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 2px;
    color: white;
  }
  .containerabmas {
    position: relative;
    top: 75%;
    margin-left: 0%;
    margin-right: 0%;
  }
  .divincreaseabmas {
    background-image: url(../images/roi.png);
    background-size: cover;

    position: relative;
    margin-top: 500px;
    height: 760px;
    padding-bottom: 0%;
    padding-top: 15%;
    width: 100%;
  }
  .cardgridas {
    display: grid;
    grid-template-columns: 1fr;
  }
  .imgcnabmas {
    width: 60vw;
    height: 50vh;
    margin-bottom: 10%;
  }
  .divabmas {
    background-image: url(../images/accountmain.png);
    background-size: cover;
    height: 100vh;
  }
  .abmpart2containeras {
    position: relative;

    margin-left: 3%;
    margin-right: 3%;
  }
}
