* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}
#h2bcdf {
  margin-top: -10px;
}
.borderbb {
  color: black !important;
  margin-left: 3%;
}
.borderbba {
  color: #1f85de !important;
  margin-left: 8%;
  border-bottom: #000 solid 1px;
  width: 40%;
}

.imagechotanav {
  margin-left: 3% !important;
  width: 70px;
  height: 70px;
  margin-top: 5%;
}
.bbc {
  display: none;
}
.logofor {
  width: 200px;
  height: 200px;
}
.logoof {
  margin-top: 13%;
}

.wrapper {
  margin: 0;
  width: 100%;
  z-index: 1001;
  max-height: 280px;
}

.mega_menu {
  width: 100%;
  min-height: 60px;
  margin: 0;
  display: flex;
}

.mega_menu ul {
  width: 100%;
  height: 100%;
  /* background: #000000; */
  text-align: center;
  line-height: 50px;
  position: relative;
}
.mega_menu ul li {
  display: inline-block;
  margin: 0 15px;
  padding: 0 15px;
}
.megagridas {
  display: block;
}
.megagridas li {
  font-size: 25px !important;
}
.mega_menu ul li a {
  text-decoration: none;
  /* color: #fff; */
  /* text-transform: upp; */
  /* font-weight: bold; */

  font-size: 20px;
  font-weight: 500;
  display: block;
}
.navbarh1as {
  font-size: 30px;
}
.navbarlias {
  font-size: 25px;
}
.wrapper .banner_img img {
  width: 100%;
  height: 500px;
}
.logonavbaras {
  width: 150px;
  height: 150px;
  margin-top: -20px;
  margin-left: 10px;
}

.mega_menu ul li .sub_menu {
  position: absolute;
  /* border: #1fde75d7 solid 5px; */
  background: white !important;
  box-shadow: 10px 10px 10px black;
  margin-left: 100px;
  margin-right: 100px;
  height: 400px;
  left: 0;

  padding: 25px 15px;

  visibility: hidden;
  line-height: 24px;
}
.submenugridas {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.h2dddss {
  font-size: 30px;
  font-weight: 600;
  color: black;
}
.redcardnavbar {
  margin-top: 6%;
  margin-left: 2%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.submenugridas2244 {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 4fr;
}
.submenugridaboutas {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.submenugridpart2as {
  margin-top: 5%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.submenugridresourceas {
  margin-top: 5%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.navbarh2as {
  font-size: 25px;
  margin-top: 20px;
  font-weight: 600;
}
.navbarh5as {
  font-size: 14px;
  line-height: 170%;
  font-weight: 500;
}
.margintopnavas {
  margin-top: 30px;
}
.part1gridas {
  border-right: rgba(128, 128, 128, 0.24) solid 2px;
  padding: 20px;
  padding-top: 20px;
  color: black;
}
.part1grid2as {
  padding: 20px;
  padding-top: 20px;
  color: black;
}
.navbarh1as {
  margin-left: 20px;
}
.mega_menu ul li:hover {
  background: transparent;
}

.mega_menu ul li:hover .navbarh2as {
  color: #000;
}
.mega_menu ul li:hover .navbarh5as {
  color: #000;
}
.mega_menu ul li:hover .navbarh2as:hover {
  color: #ca914e;
}
.mega_menu ul li:hover .navbarh5as:hover {
  color: #ca914e;
}

.mega_menu ul li:hover .sub_menu {
  visibility: visible;
}

.mega_menu ul li .sub_menu .col img {
  width: 250px;
  height: 180px;
  display: block;
}

.mega_menu ul li .sub_menu p {
  color: #fff;
  margin-top: 15px;
  font-size: 14px;
}

.mega_menu ul li .sub_menu h4 {
  color: #ca914e;
}
.navNarrow i {
  float: left;
  cursor: pointer;
  color: black;
}
.navNarrow .narrowLinks {
  display: none;
  background-color: #ffffff !important;
  height: 100vh !important;
}
.navNarrow .narrowLinks a {
  text-decoration: none;
  display: block;
  float: left;
  clear: left;
  padding: 0.5em 0;
}
.navNarrow {
  display: none;
}
.fa-plus {
  float: right;
  margin-right: 20px;
}

@media (min-width: 600px) and (max-width: 992px) {
  .imagechotanav {
    width: 100px;
    height: 100px;
    margin-top: 0% !important;
  }
  .wrapper {
    margin: 0;
    width: 100%;
    z-index: 1001;
    min-height: 20px;
  }
  .bbc {
    display: block;
  }
  /* .sidebar {
    display: block;
    position: fixed;
    transition: all 0.5s;
    transform: translateX(-30rem);
    width: 30rem;
    background-color: #f0f0f0;
    height: 100%;
  } */
  .narrowLinks {
    background-color: white;
    min-width: 100%;

    height: 500px;
  }
  .navWide {
    display: block;
  }
  .navNarrow {
    display: block;
  }
  .mega_menu {
    display: none !important;
  }

  .mega_menu ul li a {
    text-decoration: none;

    /* color: #fff; */
    text-transform: uppercase;
    /* font-weight: bold; */
    letter-spacing: 0;
    font-size: 12px;
    font-weight: 300;
    display: inline-block;
  }
  .mega_menu ul li .sub_menu {
    position: absolute;
    background: white;
    margin-left: 100px;
    margin-right: 100px;
    height: 400px;
    left: 0;
    margin-top: 20px;
    padding: 25px 15px;

    visibility: hidden;
    line-height: 22px;
  }
  .logonavbaras {
    width: 130px;
    height: 50px;
    margin-top: 5%;
    margin-left: 10px;
  }
  .wrapper {
    margin: 0;
    width: 100%;
    z-index: 1001;
    min-height: 150px;
  }

  .mega_menu {
    width: 100%;
    min-height: 60px;
    margin: 0;
    display: flex;
  }
  .narrowLinksadd {
    max-height: 400px;
    display: none;
  }
  .narrowLinksadd1 {
    max-height: 400px;
    display: none;
  }
  .narrowLinksadd2 {
    max-height: 400px;
    display: none;
  }
  .narrowLinksadd3 {
    max-height: 400px;
    display: none;
  }
  .narrowLinksadd4 {
    max-height: 400px;
    display: none;
  }
  .narrowLinksadd5 {
    max-height: 400px;
    display: none;
  }
  .faicondiv {
    float: right;
  }

  .mega_menu ul {
    width: 100%;
    height: 100%;
    /* background: #000000; */
    text-align: center;
    line-height: 50px;
    position: relative;
  }
  .narrowLinksadd {
    display: none;
    background-color: white;
  }
  .navNarrow {
    display: block;
    background-color: transparent;
  }
}
@media (min-width: 400px) and (max-width: 600px) {
  .bbc {
    display: block;
  }
  .narrowLinksadd1 {
    min-height: 400px;
    display: none;
  }
  .narrowLinksadd2 {
    min-height: 400px;
    display: none;
  }
  .narrowLinksadd3 {
    min-height: 400px;
    display: none;
  }
  .narrowLinksadd4 {
    min-height: 400px;
    display: none;
  }
  .narrowLinksadd5 {
    min-height: 400px;
    display: none;
  }
  .fa-plus {
    position: relative;
    right: 0;
  }
  .faicondiv {
    float: right;
  }
  .wrapper {
    margin: 0;
    width: 100%;
    z-index: 1001;
    min-height: 20px;
  }
  .narrowLinks {
    background-color: white !important;
    width: 100%;
    min-height: 500px;
  }
  .narrowLinksadd {
    display: none;
  }
  .narrowLinksadd {
    display: none;
    background-color: white;
  }
  .navNarrow {
    display: block;
    background-color: white;
  }
  .navWide {
    display: block;
  }
  .navNarrow {
    display: block;
  }
  .mega_menu {
    display: none !important;
  }
  .mega_menu ul li a {
    text-decoration: none;

    /* color: #fff; */
    text-transform: uppercase;
    /* font-weight: bold; */
    letter-spacing: 0;
    font-size: 12px;
    font-weight: 300;
    display: inline-block;
  }
  .fa-plus {
    position: relative;

    right: 0;
  }
  .fa-plus {
    position: relative;
    top: 5px;
    right: 0;
  }
}
@media (max-width: 400px) {
  .iconfloats {
    margin-bottom: -5px !important;
  }
  .fa-plus {
    position: relative;
    top: 5px;
    right: 0;
  }
  .faicondiv {
    float: right;
  }
  .bbc {
    display: block;
  }
  .narrowLinksadd1 {
    min-height: 400px;
    display: none;
  }
  .narrowLinksadd2 {
    min-height: 400px;
    display: none;
  }
  .narrowLinksadd3 {
    min-height: 400px;
    display: none;
  }
  .narrowLinksadd4 {
    min-height: 400px;
    display: none;
  }
  .narrowLinksadd5 {
    min-height: 400px;
    display: none;
  }
  .wrapper {
    margin: 0;
    width: 100%;
    z-index: 1001;
    min-height: 20px;
  }
  .navWide {
    display: block;
  }
  .narrowLinksadd {
    display: none;
    background-color: white;
  }
  .navNarrow {
    display: block;
    background-color: white;
  }
  .mega_menu {
    display: none !important;
  }
  .narrowLinks {
    background-color: white !important;
    width: 100%;
    height: 100%;
  }
}

/* Sidebar */

.brand button {
  font-size: 3rem;
  padding: 0.5rem;
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
}
.sidebar {
  position: fixed;
  transition: all 0.5s;
  transform: translateX(-30rem);
  width: 30rem;
  background-color: #f0f0f0;
  height: 100%;
}
.sidebar.open {
  transform: translateX(0);
}

.sidebar-close-button {
  border-radius: 50%;
  border: 0.1rem #000000 solid;
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  font-size: 2rem;
  padding-top: 0;
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  top: 1.5rem;
}
