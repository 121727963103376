.redborder {
  background: linear-gradient(
    to right,
    rgba(219, 7, 60, 0.797),
    rgba(219, 85, 7, 0.7)
  );
  text-transform: uppercase;
  text-align: center;
  height: 50px;
  font-size: 23px;
  letter-spacing: 2px;
  font-weight: 500;
  padding-top: 10px;
  width: 100%;
  color: white;
}

.redcardbody {
  height: 300px;
  width: 350px;
}
.middleclass {
  margin-left: 5%;
  text-align: center;
  margin-right: 5%;
  font-size: 16px;
  margin-top: 10%;
  color: rgba(0, 0, 0, 0.637);
}
.middleclass22 {
  text-align: center;
  margin-right: 5%;
  font-size: 16px;

  color: rgba(0, 0, 0, 0.637);
}
.lastdiv {
  height: 10px;
  width: 100%;
  margin-top: 25%;
  background: linear-gradient(
    to right,
    rgba(219, 7, 60, 0.797),
    rgba(219, 85, 7, 0.7)
  );
}
.lastdiv1 {
  height: 10px;
  width: 100%;
  margin-top: 15%;
  background: linear-gradient(
    to right,
    rgba(219, 7, 60, 0.797),
    rgba(219, 85, 7, 0.7)
  );
}
.middleclass1 {
  margin-left: 5%;
  text-align: center;
  margin-right: 5%;
  font-size: 18px;
  font-weight: 600;
  margin-top: 5%;
  color: #1f85de;
}
.madeupalright {
  height: 18vh;
  width: 10vw;
}
@media (max-width: 400px) {
  .redcardbody {
    height: 300px;
    width: 300px;
  }
}
