.resourcesheaderimage {
  background-image: url("../images/contentsyndication_background.png");
  height: 60vh;
  width: 100%;
}
.WhitePaperhaeding {
  color: rgb(0, 217, 255);
  margin-left: 10%;
  border-bottom: 1px solid grey;
  margin-right: 75%;
  font-size: 40px;
  text-align: center;
}
.h1HeadingOfHeaderImageKV {
  font-size: 60px;
  color: white;
  padding: 12%;
}
.gridTemplateresourcesKV {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.sameButtonForAllresourceKV {
  margin-top: -50px !important;
  margin-left: 180px !important;
  text-decoration: none;
}
.resourceCardfORdIFFERENTcARDkv {
  margin-left: 60px;
  margin-right: 60px;
}
.moj {
  margin-top: 10%;
  margin-bottom: 10%;
  border-bottom: grey 2px solid;
  margin-left: 10%;
  margin-right: 10%;
}
.linkclass {
  text-decoration: none;
}
.style14 {
  border: 0;
  height: px;
  background-image: -webkit-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -moz-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -ms-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -o-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
}
@media (min-width: 400px) and (max-width: 600px) {
  .gridTemplateresourcesKV {
    display: grid;
    grid-template-columns: 1fr;
  }
}

@media (min-width: 800px) and (max-width: 1024px) {
  .gridTemplateresourcesKV {
    display: grid;
    grid-template-columns: 1fr;
  }

  .sameButtonForAllresourceKV {
    margin-top: 260px !important;
    margin-left: 380px !important;
  }
  .resourceCardfORdIFFERENTcARDkv {
    margin-left: 0px;
    margin-right: 0px;
  }
  .resourcesheaderimage {
    height: 50vh;
    width: 100% !important;
  }
  .h1HeadingOfHeaderImageKV {
    font-size: 70px;
    color: white;
    padding: 25%;
    padding-left: 5%;
  }
}

@media (min-width: 600px) and (max-width: 800px) {
  .gridTemplateresourcesKV {
    display: grid;
    grid-template-columns: 1fr;
  }

  .sameButtonForAllresourceKV {
    margin-top: 280px !important;
    margin-left: 280px !important;
  }
  .resourceCardfORdIFFERENTcARDkv {
    margin-left: 0px;
    margin-right: 0px;
  }
  .resourcesheaderimage {
    height: 50vh;
    width: 100% !important;
  }
  .h1HeadingOfHeaderImageKV {
    font-size: 70px;
    color: white;
    padding: 25%;
    padding-left: 5%;
  }
}

@media (min-width: 350px) and (max-width: 600px) {
  .gridTemplateresourcesKV {
    display: grid;
    grid-template-columns: 1fr;
  }

  .sameButtonForAllresourceKV {
    margin-top: 140px !important;
    margin-left: 120px !important;
  }
  .resourceCardfORdIFFERENTcARDkv {
    margin-left: 0px;
    margin-right: 0px;
  }
  .resourcesheaderimage {
    height: 50vh;
    width: 100% !important;
  }
  .h1HeadingOfHeaderImageKV {
    font-size: 40px;
    color: white;
    padding: 25%;
    padding-left: 5%;
  }
}
