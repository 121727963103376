.wallpaper4as {
  background-image: url(../images/header.png);
  background-size: cover;
  height: 105vh;
  width: 100%;
}
.marginleftvsright {
  margin-left: 150px;
  margin-right: 150px;
}
.V3-benefits-kp {
  position: relative;
}

.V3-benefits-img-div-kp {
  width: 100%;
}

.V3-benefits-img-kp {
  width: 100%;
}

.V3-benefits-text-div-kp {
  position: absolute;
  top: 35%;
  width: 45%;
  margin-left: 100px;
  padding-right: 100px;
  display: grid;
  grid-template-rows: 2fr 2fr 2fr 1fr 2fr;
  align-items: center;
  grid-gap: 2%;
  color: white;
}

.V3-benefits-h2-kp {
  font-size: 30px;
}

.V3-benefits-para1-kp {
  font-size: 18px;
}

.V3-benefits-para2-kp {
  font-size: 18px;
}

.V3-benefits-para3-kp {
  font-size: 18px;
}

.V3-benefits-button-kp {
  outline: none;
  background-color: #ffa500;
  font-size: 1.15rem;
  text-transform: uppercase;
  padding: 0.8rem 0.8rem;
  border-radius: 50px;
  border: none;
  color: white;
  transition: 0.25s linear;
  justify-self: baseline;
}

.V3-benefits-button-kp:hover {
  box-shadow: 0 0 20px #ffa500;
  border: 1px solid #ffa500;
  background-color: transparent;
  color: white;
  cursor: pointer;
}
/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
.V3-about-kp {
  margin: 0 40px;
  display: grid;
  grid-template-columns: 4fr 3fr;
  grid-template-rows: auto;
  grid-column-gap: 100px;
  margin-bottom: 100px;
  align-items: center;
}

.V3-about-img-kp {
  width: 100%;
}

.V3-about-text-div-kp {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  align-items: center;
}

.V3-about-h2-kp {
  font-size: 42px;
}

.V3-about-para1-kp {
  color: grey;
  font-size: 16px;
}

.V3-about-para2-kp {
  color: grey;
  font-size: 16px;
}

.V3-about-button-kp {
  outline: none;
  background-color: #ffa500;
  font-size: 1.15rem;
  justify-self: baseline;
  text-transform: uppercase;
  padding: 0.8rem 0.8rem;
  border-radius: 50px;
  border: none;
  color: white;
  transition: 0.25s linear;
}

.V3-about-button-kp:hover {
  box-shadow: 0 0 20px #ffa500;
  border: 1px solid #ffa500;
  background-color: transparent;
  color: black;
  cursor: pointer;
}
