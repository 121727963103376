* {
  margin: 0;
  padding: 0;
}
.cardvenaliciumasdj {
  position: relative;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 20px;
  /* border: 2px solid red; */
  height: 300px;
  margin-bottom: 100px;
  width: 300px;
  padding: 20% 2% 2% 2%;
}
.cardvenaliciumasdj:hover {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.678) 0px 0px 40px;
}
.cardvenaliciumas {
  position: relative;
  border-radius: 60px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 20px;
  /* border: 2px solid red; */
  height: 500px;
  margin-bottom: 100px;
  width: 350px;
}
.cardvenaliciumas:hover {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.678) 0px 0px 40px;
}
.cardimgas {
  margin-top: 20px;
  height: 250px;
  width: 80%;
  margin-left: 30px;
  margin-bottom: 20px;
}

.cardh1jsas {
  text-align: center;
  font-size: 25px;
  color: #dec11f;
}
.cardh3jsas {
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: gray;
}
@media (max-width: 400px) {
  .cardvenaliciumasdj {
    position: relative;
    border-radius: 10px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 20px;
    /* border: 2px solid red; */
    height: 250px;
    margin-bottom: 100px;
    width: 250px;
    padding: 20% 2% 2% 2%;
  }
  .cardvenaliciumasdj:hover {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.678) 0px 0px 40px;
  }
  .cardvenaliciumas {
    position: relative;
    border-radius: 60px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 20px;
    /* border: 2px solid red; */
    height: 500px;
    margin-bottom: 100px;
    width: 300px;
  }
  .cardvenaliciumas:hover {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.678) 0px 0px 40px;
  }
  .cardimgas {
    margin-top: 20px;
    height: 250px;
    width: 80%;
    margin-left: 30px;
    margin-bottom: 20px;
  }

  .cardh1jsas {
    text-align: center;
    font-size: 25px;
    color: #dec11f;
  }
  .cardh3jsas {
    margin-left: 50px;
    margin-right: 50px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: gray;
  }
}
