.cardvenaliciumdjas {
  position: relative;
  background: transparent;

  box-shadow: rgba(0, 0, 0, 0.371) 0px 0px 20px;
  /* border: 2px solid red; */
  border-radius: 50px;
  height: 250px;
  margin-left: 70px;
  margin-bottom: 100px;
  width: 250px;
}
.cardvenaliciumdjas:hover {
  position: relative;
  /* border-radius: 60px; */

  box-shadow: rgba(0, 0, 0, 0.671) 5px 5px 10px;
  /* border: 2px solid red; */
}
.cardvault {
  display: flex;
  justify-content: center;
  align-content: center;
}
.cardimgdjas {
  /* margin-top: 20px; */
  height: 250px;
  border-radius: 50px;
  width: 100%;
  background-color: purple;
  /* padding-left: 50px;
  padding-right: 50px; */
  margin-bottom: 20px;
}
.cardimgdjas:hover {
  /* margin-top: 20px; */
  height: 250px;
  background-color: turquoise;
  /* width: 100%; */
  /* padding-left: 50px;
  padding-right: 50px; */
  margin-bottom: 20px;
}
