#firstpurple{
  transform: scale(1);
  transition: all 0.2s ease-in;
  transform-origin: center;
  

}
#firstpurple:hover{
  transform: scale(1.06);
}
.funnelbody{
  text-align: center;
}
#secondblue{
  transform: scale(1);
  transition: all 0.2s ease-in;
  transform-origin: center;
  

}
#secondblue:hover{
  transform: scale(1.1);
}
#thirdblue{
  transform: scale(1);
  transition: all 0.2s ease-in;
  transform-origin: center;
  

}
#thirdblue:hover{
  transform: scale(1.1);
}
#fourthgreen{
  transform: scale(1);
  transition: all 0.2s ease-in;
  transform-origin: center;
  

}
#fourthgreen:hover{
  transform: scale(1.1);
}
#fifthyellow{
  transform: scale(1);
  transition: all 0.3s ease-in;
  transform-origin: center;
  

}
#fifthyellow:hover{
  transform: scale(1.1);
}
#sixthred{
  transform: scale(1);
  transition: all 0.3s ease-in;
  transform-origin: center;
  

}
#sixthred:hover{
  transform: scale(1.1);
}

@media screen and (max-width:500px){
  .funnelbody{
    display: none;
  }
}