.wallpaper7as {
  height: 110vh;
  width: 100%;
}
.wallpaper7 {
  max-height: 100vh;
  width: 100%;
}
.optimizeasdj {
  background-image: url(../images/optimize.png);
  background-size: cover;
  height: 120vh;
  width: 100%;
  margin-bottom: 10%;
}
.gridfortick {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.headingoptimize {
  margin-top: 20%;
  margin-left: 10%;
  font-size: 40px;
  font-weight: 700;
}
.tick {
  height: 50px;
  width: 50px;
}
.ticksub {
  margin-top: 3%;
  margin-left: 20%;
  display: flex;
  margin-right: 10%;
}
.tick1 {
  margin-top: 1%;
  font-size: 17px;
}
.parafortick {
  margin-right: 10%;
  margin-top: 3%;
  margin-left: 20%;
  font-size: 17px;
  color: rgba(128, 128, 128, 0.603);
}
.bottom-div {
  margin-top: 5%;
  font-size: 20px;
  margin-right: 10%;
  margin-left: 10%;
  color: rgba(0, 0, 0, 0.664);
}
.forgridcardpink {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 10%;
  /* margin-bottom: 10%; */
  margin-left: 10%;
  margin-right: 10%;
}
.maincarddj {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-left: 4%;
  margin-right: 5%;
}
.imgcardfor6 {
  height: 500px;
  margin-left: 0;
  margin-right: 0;
  width: 450px;
}
.maincaard2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 15%;
}
