* {
  margin: 0;
  padding: 0;
}
.maindivsmallcardsKV {
  padding: 0;
  margin-bottom: 300px;
}
.maindivsmallcardsKV1 {
  padding: 0;
}
.cardvenaliciumKV1 {
  position: absolute;
  border-radius: 10px;
  background-color: white;

  /* border: 2px solid red; */
  height: 180px;
  margin-bottom: 100px;
  width: 130px;
}
.cardvenaliciumKV1:hover {
  background: linear-gradient(
    to bottom,
    rgba(219, 67, 7, 0.897),
    rgba(219, 120, 7, 0.7)
  );
  color: white;
}
.cardvenaliciumKV {
  position: absolute;
  border-radius: 10px;
  background-color: white;

  box-shadow: rgba(0, 0, 0, 0.671) 5px 5px 10px;
  /* border: 2px solid red; */
  height: 180px;
  margin-bottom: 100px;
  width: 150px;
}
.cardvenaliciumKV:hover {
  box-shadow: black 5px 10px 30px;
  background: linear-gradient(
    to bottom,
    rgba(219, 67, 7, 0.897),
    rgba(219, 120, 7, 0.7)
  );
}

.cardimgKV1 {
  padding-top: 30px;
  height: 119.1px;
  width: 103.18px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 20px;
  margin-bottom: 20px;
}
.cardimgKV {
  padding-top: 30px;
  height: 119.1px;
  width: 103.18px;
  margin-left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 20px;
  margin-bottom: 20px;
}

.cardh1jsKV {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: black;
  margin-top: 30px;
}
.cardh1jsKV1 {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: black;
  margin-top: 30px;
}
.cardvenaliciumKV1:hover .cardh1jsKV1 {
  color: white;
}

.cardh3jsKV {
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  font-weight: 400;
  color: gray;
}
