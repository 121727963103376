.mainDivContainerImageHeader {
  margin-bottom: 5%;
  background-image: url("../images/contentsyndication_background.png");
  width: 100%;
  height: 500px;
  padding-top: 10%;
  padding-right: 40%;
}
.headerofContainerHeaderH1KV {
  font-size: 40px;
  color: white;
  font-weight: 400;
  margin-left: 10%;
}
.gridCategoryheymaincontainerKV {
  margin-bottom: 10%;
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.divofcontentwhitePaperKV {
  color: grey;
  font-size: 20px;
  margin-top: 5%;
  margin-right: 10%;
  margin-left: 10%;
}

.aaa {
  flex: 1;
  padding: 18px 40px;
  font-size: 16px;
  color: rgba(41, 214, 206, 0.801);
  background: transparent;
  border: none;
  border-bottom: rgba(0, 225, 255, 0.829) solid 2px;
  font-weight: 700;
  outline: none;
  padding-left: 5px;
  width: 60%;
  margin-left: 17%;
  margin-top: 10%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.labelas {
  color: rgba(41, 214, 206, 0.801);
}
.aab {
  margin-left: 18%;
  margin-top: 10%;
}
.contactDownloadFormKV {
  background-image: url(../images/iphone.png);
  background-size: cover;

  border-radius: 5px;
  height: 600px;
  width: 400px;
}
.button {
  margin-left: 24%;
  margin-top: 10%;
  width: 200px;
  height: 50px;
  color: white;
  font-size: 25px;
  border-radius: 5px;
  background: linear-gradient(
    to right,
    rgba(0, 153, 213, 0.556),
    rgba(53, 229, 176, 0.336)
  );
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  color: black;
  background: linear-gradient(
    to right,
    rgba(0, 153, 213, 0.356),
    rgba(53, 229, 176, 0.336)
  );

  font-weight: 700;
}
