.coverDivInMeetingKV {
  background-image: url("../images/Turn-Key@2x.png");
  background-size: cover;
  height: 120vh;
  width: 100%;
}
.h1InMeetingDivKVCover {
  font-size: 70px;
  font-weight: 600;
  color: white;
  text-align: start;
  position: relative;
  top: 350px;
  margin-left: 100px;
}
.h1coverinMeetingDivKV {
  font-size: 50px;
  font-weight: 500;
  color: white;
  position: relative;
  top: 300px;
  text-align: start;
  margin-left: 100px;
}
.Div1InMeetingKV {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 70vh;
  margin-top: 100px;
}
.TakeOwnershipImageInmeetingKV {
  width: 80%;
  height: 80%;
}
.h1Div1InmeetingTakeOwnerKV {
  font-size: 40px;
  font-weight: 500;
  margin-left: 100px;
  margin-top: 120px;
}
.paraDiv1TakeownershipInmeetingKV {
  font-size: 25px;
  font-weight: 400;
  color: #888888;
  margin-left: 100px;
}
.Div2InmeetingKV {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.buttonDiv1TakenownershipKV {
  margin-left: 170px;
  margin-top: 80px;
}
.Div2InmeetingKV {
  background-image: url("../images/bg\ 2_1@2x.png");
  background-size: cover;
  height: 105vh;
  width: 100%;
  margin-top: 100px;
}
.h1div2InMeetingHOWWORKkv {
  font-size: 45px;
  color: white;
  margin-top: 30%;
  margin-left: 100px;
  font-weight: 600;
}
.paraDiv2HowWorkKV {
  font-size: 20px;
  margin-top: 30px;
  font-weight: 400;
  margin-left: 100px;
  color: white;
}
.HowWorkImageDiv2KV {
  width: 100%;
  height: 100%;
  margin-top: 30%;
  margin-left: 0px;
}
.Div4InMeetingKV {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-left: 10%;
  color: #888888;
}
.headingDiv3InMeetingKV {
  font-size: 25px;
  text-align: center;
  font-weight: 500;
  margin-top: 100px;
  margin-bottom: 100px;
}
.Div3rdInMeetingBofuKV {
  background-image: url(../images/contentbg_contentsyndication.png);
  background-size: cover;
  height: 120vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.h1Div3rdInMeetingBofuKV {
  font-size: 45px;
  color: white;
  margin-top: 330px;
  margin-left: 100px;
  font-weight: 600;
}
.paraDiv3rdMeetingBofuKV {
  font-size: 20px;
  margin-top: 30px;
  font-weight: 400;
  margin-left: 100px;
  color: white;
}
.ImageDiv3InMeetingInMarketKV {
  width: 100%;
  height: 100%;
  margin-top: 280px;
}

.contactusInMeetingKV {
  height: 30vh;
  width: 100%;
  margin-top: 200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.ImagecontactUsInMeetingKV {
  width: 30%;
  height: 30%;
  margin-left: 50px;
}

.h1contactusInMeetingKV {
  font-weight: 400;
  font-size: 25px;
  color: #888888;
  text-align: center;
}
.buttonContactusInMeetingKV {
  margin-left: 250px;
  margin-top: 30px;
}

.smallCrdInMeetingKV {
  width: 100%;
  min-height: 500px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.headingDiv4InMeetingKV {
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  padding: 95px;
}
#pehlasmallCrdInMeetingKV {
  padding-left: 170px;
}

/* MEDIA QUERY */

@media (max-width: 600px) {
  .h1InMeetingDivKVCover {
    font-size: 40px;
    top: 300px;
    margin-left: 10px;
  }
  .h1coverinMeetingDivKV {
    font-size: 30px;
    top: 270px;
    margin-left: 10px;
  }
  .Div1InMeetingKV {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    height: 70vh;
    margin-top: 100px;
  }
  .TakeOwnershipImageInmeetingKV {
    display: none;
  }
  .h1Div1InmeetingTakeOwnerKV {
    font-size: 30px;
    margin-left: 10px;
  }
  .paraDiv1TakeownershipInmeetingKV {
    font-size: 15px;
    margin-left: 10px;
  }
  .buttonDiv1TakenownershipKV {
    margin-left: 100px;
  }

  .Div2InmeetingKV {
    grid-template-columns: 1fr;
    height: 130vh;
  }

  .h1div2InMeetingHOWWORKkv {
    font-size: 45px;
    color: white;
    margin-top: 160px;
    margin-left: 45px;
    font-weight: 600;
  }
  .paraDiv2HowWorkKV {
    font-size: 15px;
    margin-top: 30px;
    padding-right: 50px;
    font-weight: 400;
    margin-left: 10px;
    color: white;
  }
  .HowWorkImageDiv2KV {
    width: 90%;
    height: 90%;
    margin-top: 0px;
  }
  .smallCrdInMeetingKV {
    width: 80%;
    min-height: 500px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .headingDiv4InMeetingKV {
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    padding: 95px;
  }
  .Div4InMeetingKV {
    grid-template-columns: 1fr;
    padding-left: 45px;
  }

  .Div3rdInMeetingBofuKV {
    min-height: 120vh;
    width: 90%;
    grid-template-columns: 1fr;
  }
  .h1Div3rdInMeetingBofuKV {
    font-size: 45px;
    color: white;
    margin-top: 330px;
    margin-left: 100px;
    font-weight: 600;
  }
  .paraDiv3rdMeetingBofuKV {
    font-size: 20px;
    margin-top: 30px;
    font-weight: 400;
    margin-left: 100px;
    color: white;
  }
  .ImageDiv3InMeetingInMarketKV {
    width: 100%;
    height: 100%;
    margin-top: 280px;
  }
  .cardkocoverkarnekliyeKV {
    height: 1500px;
  }

  .Div3rdInMeetingBofuKV {
    height: 150vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }
  .h1Div3rdInMeetingBofuKV {
    font-size: 25px;
    color: white;
    margin-top: 430px;
    margin-left: 10px;
    font-weight: 400;
  }
  .paraDiv3rdMeetingBofuKV {
    font-size: 15px;
    margin-top: 30px;
    font-weight: 400;
    margin-left: 10px;
    color: white;
  }
  .ImageDiv3InMeetingInMarketKV {
    width: 80%;
    height: 80%;
    margin-top: 0px;
  }

  .contactusInMeetingKV {
    min-height: 30vh;
    width: 100%;
    margin-top: 200px;
    display: grid;
    grid-template-columns: 1fr;
  }
  .ImagecontactUsInMeetingKV {
    width: 35%;
    height: 35%;
    margin-left: 0px;
  }

  .h1contactusInMeetingKV {
    font-weight: 300;
    font-size: 15px;
    color: #888888;
    text-align: start;
  }
  .buttonContactusInMeetingKV {
    margin-left: 200px;
    margin-top: 30px;
  }
}
