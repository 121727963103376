@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");
.ResourceCardMainContainerKV {
  height: 400px;
  width: 500px;
  margin-top: 30%;
  margin-left: 10%;
}
.imageContainerOfResourceCardKV {
  height: 250px;
  width: 500px;
}
.titleContainerOfResourceKV {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: rgb(0, 162, 255);
}
.titleresourcecardKV {
  margin: 20px 0;
  text-align: center;
}
@media (min-width: 400px) and (max-width: 600px) {
  .ResourceCardMainContainerKV {
    height: 400px;
    width: 500px;
    margin-top: 30%;
    margin-left: 10%;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .ResourceCardMainContainerKV {
    height: 400px;
    width: 700px;
    margin-top: 40%;
    margin-left: 6%;
  }

  .imageContainerOfResourceCardKV {
    height: 400px;
    width: 700px;
  }
  .titleContainerOfResourceKV {
    font-family: "Open Sans", sans-serif;
    font-size: 42px;
    font-weight: 400;
    color: #8b20e8;
  }
  .titleresourcecardKV {
    margin: 40px 0;
    text-align: center;
  }
}

@media (min-width: 350px) and (max-width: 600px) {
  .ResourceCardMainContainerKV {
    height: 300px;
    width: 370px;
    margin-top: 10%;
    margin-left: 0%;
  }

  .imageContainerOfResourceCardKV {
    height: 300px;
    width: 370px;
  }
  .titleContainerOfResourceKV {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #8b20e8;
  }
  .titleresourcecardKV {
    margin: 40px 0;
    text-align: center;
  }
}
