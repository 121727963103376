* {
  margin: 0;
  padding: 0;
}
.wallpaper5as {
  background-image: url(../images/bgsc.png);
  background-size: cover;
  height: 110vh;
  width: 100%;
}
.headingwallpaper1 {
  text-align: center;
  position: relative;
  top: 20%;
}
.h2wallpaper1 {
  text-transform: uppercase;
  font-size: 40px;
  color: rgba(255, 255, 255, 0.877);
}
.h1wallpaper1 {
  text-align: center;
  font-size: 60px;
  font-weight: 700;
  color: white;
}
.imgsrcaadd {
  z-index: 10;
  height: 40%;
  width: 60%;
}
.logomiddle {
  margin-top: -50%;
  height: 10%;
  width: 20%;
  z-index: 50;
}
.imgdivsrccaad {
  z-index: 40;
  margin-top: -70%;
  padding-top: -40%;
}
@media (min-width: 996px) and (max-width: 1280px) {
  .wallpaper5as {
    background-image: url(../images/bgsc.png);
    background-size: cover;
    height: 70vh;
    width: 100%;
  }
}
@media (min-width: 700px) and (max-width: 996px) {
  .wallpaper5as {
    background-image: url(../images/bgsc.png);
    background-size: cover;
    height: 60vh;
    width: 100%;
  }
}
@media (min-width: 400px) and (max-width: 700px) {
  .wallpaper5as {
    background-image: url(../images/bgsc.png);
    background-size: cover;
    height: 60vh;
    width: 100%;
  }
}
@media (max-width: 400px) {
  .wallpaper5as {
    background-image: url(../images/bgsc.png);
    background-size: cover;
    height: 100vh;
    width: 100%;
  }
  .headingwallpaper1 {
    text-align: center;
    position: relative;
    top: 30%;
  }
}
