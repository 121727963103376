.clock {
  width: 120px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  border-radius: 5px;
  margin: 5px;
  /* background: #091921; */
}
.clock .digital-clock {
  padding: 10px;
}
.clock .analog-clock {
  background-image: url(../images/wpchuha.png);
  border: 4px solid white;
  background-size: cover;
  width: 150px;
  height: 150px;

  border-radius: 50%;

  position: relative;
}
.clock .analog-clock .dial {
  position: absolute;
  left: 50%;
  width: 50px;
  height: 70px;
  transform-origin: bottom left;
}
.clock .analog-clock .dial.seconds {
  border-left: solid 1px black;
}
.clock .analog-clock .dial.minutes {
  border-left: solid 2px rgb(46, 255, 4);
}
.clock .analog-clock .dial.hours {
  width: 35px;
  height: 35px;
  top: 35px;
  border-left: solid 3px #db2c49;
}
