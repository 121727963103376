.wallpaper11as {
  background-image: url(../images/it_1.svg);
  background-size: cover;
  height: 110vh;
  width: 100%;
}

.wallpaper12as {
  background-image: url(../images/business_1.svg);
  background-size: cover;
  height: 110vh;
  width: 100%;
}

.wallpaper14as {
  background-image: url(../images/hr_1.svg);
  background-size: cover;
  height: 110vh;
  width: 100%;
}

.wallpaper13as {
  background-image: url(../images/sales.svg);
  background-size: cover;
  height: 110vh;
  width: 100%;
}

.wallpaper15as {
  background-image: url(../images/Finance.svg);
  background-size: cover;
  height: 110vh;
  width: 100%;
}

.wallpaper16as {
  background-image: url(../images/marketing.svg);
  background-size: cover;
  height: 110vh;
  width: 100%;
}

@media (min-width: 996px) and (max-width: 1280px) {
  .wallpaper11as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 60vh;
    width: 100%;
  }

  .wallpaper12as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 60vh;
    width: 100%;
  }

  .wallpaper14as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 60vh;
    width: 100%;
  }

  .wallpaper13as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 60vh;
    width: 100%;
  }

  .wallpaper15as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 60vh;
    width: 100%;
  }

  .wallpaper16as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 60vh;
    width: 100%;
  }
}

@media (min-width: 700px) and (max-width: 996px) {
  .wallpaper11as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 60vh;
    width: 100%;
  }

  .wallpaper12as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 60vh;
    width: 100%;
  }

  .wallpaper14as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 60vh;
    width: 100%;
  }

  .wallpaper13as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 60vh;
    width: 100%;
  }

  .wallpaper15as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 60vh;
    width: 100%;
  }

  .wallpaper16as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 60vh;
    width: 100%;
  }
}

@media (min-width: 400px) and (max-width: 700px) {
  .wallpaper11as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 90vh;
    width: 100%;
  }

  .wallpaper12as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 90vh;
    width: 100%;
  }

  .wallpaper14as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 90vh;
    width: 100%;
  }

  .wallpaper13as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 90vh;
    width: 100%;
  }

  .wallpaper15as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 90vh;
    width: 100%;
  }

  .wallpaper16as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 90vh;
    width: 100%;
  }
}

@media (max-width: 400px) {
  .wallpaper11as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 90vh;
    width: 100%;
  }

  .wallpaper12as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 90vh;
    width: 100%;
  }

  .wallpaper14as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 90vh;
    width: 100%;
  }

  .wallpaper13as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 90vh;
    width: 100%;
  }

  .wallpaper15as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 90vh;
    width: 100%;
  }

  .wallpaper16as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 90vh;
    width: 100%;
  }
}