.logogroup {
  margin-bottom: 5%;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
}

.wallpaper10as {
  background-image: url('../images/audiencepart.svg');
  background-size: cover;
  height: 110vh;
  width: 100%;
}

.logogrid {
  margin-left: 30%;
  display: grid;
  grid-template-rows: 1fr;
}

.logonavbarasdj {
  width: 200px;
  height: 200px;
  margin-top: 2%;
  margin-left: 5px;
}

.h3logofirstas {
  margin-left: 8%;
  margin-bottom: 10%;
  font-size: 20px;
  color: #ffa800;
}

.h3logomiddleas {
  margin-left: 8%;
  color: #1f85de;
  margin-bottom: 10%;

  font-size: 20px;
}

.h3logolastas {
  margin-left: 8%;
  margin-bottom: 10%;
  font-size: 20px;
  color: #32c24f;
}

.finalgrid {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
}

.backgrid {
  background-image: url(../images/world.png);
  background-size: cover;
  height: 95vh;
  width: 100%;
}

.formargindiv {
  margin-top: 25%;
  margin-left: 20%;
  margin-right: 10%;
}

@media (min-width: 996px) and (max-width: 1380px) {
  .wallpaper10as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 70vh;
    width: 100%;
  }

  .aosbs {
    padding-top: 20%;
  }

  .backgrid {
    background-image: url(../images/world.png);
    background-size: cover;
    height: 55vh;
    width: 100%;
  }
}

@media (min-width: 700px) and (max-width: 996px) {
  .formargindiv {
    margin-top: 25%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%;
  }

  .finalgrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .wallpaper10as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 70vh;
    width: 100%;
  }

  .aosbs {
    padding-top: 20%;
  }

  .backgrid {
    background-image: url(../images/world.png);
    background-size: cover;
    height: auto;
    width: 100%;
  }

  .logogrid {
    margin-left: 10%;
    display: grid;
    grid-template-rows: 1fr;
  }

  .logonavbarasdj {
    width: 200px;
    height: 200px;
    margin-top: 2%;
    margin-left: -10px;
  }
}

@media (min-width: 400px) and (max-width: 700px) {
  .formargindiv {
    margin-top: 25%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%;
  }

  .finalgrid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .wallpaper10as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 70vh;
    width: 100%;
    margin-bottom: 10%;
  }

  .aosbs {
    padding-top: 20%;
  }

  .backgrid {
    background-image: url(../images/world.png);
    background-size: cover;
    height: 55vh;
    width: 100%;
  }

  .logogroup {
    margin-bottom: 10%;
  }

  .logogrid {
    margin-left: 0%;
    display: grid;
  }

  .logonavbarasdj {
    width: 100px;
    height: 100px;
    margin-top: 2%;
    margin-left: 10px;
  }

  .h3logofirstas {
    margin-left: 8%;
    margin-bottom: 10%;
    font-size: 16px;
    color: #ffa800;
  }

  .h3logomiddleas {
    margin-left: 8%;
    color: #1f85de;
    margin-bottom: 10%;

    font-size: 16px;
  }

  .h3logolastas {
    margin-left: 8%;
    margin-bottom: 10%;
    font-size: 16px;
    color: #32c24f;
  }
}

@media (max-width: 400px) {
  .finalgrid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .wallpaper10as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 90vh;
    width: 100%;
    margin-bottom: 20%;
  }

  .backgrid {
    background-image: url(../images/world.png);
    background-size: cover;
    height: 55vh;
    width: 100%;
  }

  .logogroup {
    margin-bottom: 10%;
  }

  .logogrid {
    margin-left: 0%;
    display: grid;
  }

  .logonavbarasdj {
    width: 100px;
    height: 100px;
    margin-top: 2%;
    margin-left: 10px;
  }

  .h3logofirstas {
    margin-left: 8%;
    margin-bottom: 10%;
    font-size: 16px;
    color: #ffa800;
  }

  .h3logomiddleas {
    margin-left: 8%;
    color: #1f85de;
    margin-bottom: 10%;

    font-size: 16px;
  }

  .h3logolastas {
    margin-left: 8%;
    margin-bottom: 10%;
    font-size: 16px;
    color: #32c24f;
  }
}