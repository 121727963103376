* {
  margin: 0;
  padding: 0;
}
.wallpaper3as {
  background-image: url(../images/inmarketops.png);
  background-size: cover;
  height: 110vh;
  width: 100%;
}
.headingshifting {
  position: relative;
  top: 50px;
}
.headingwallpaper1 {
  text-align: center;
  position: relative;
  top: 20%;
}
.h2wallpaper1 {
  text-transform: uppercase;
  font-size: 40px;
  color: rgba(255, 255, 255, 0.877);
}
.h1wallpaper1 {
  text-align: center;
  font-size: 60px;
  font-weight: 700;
  color: white;
}
.CoverPhotoInMarketKV {
  width: 100%;
  margin-bottom: 500px;
}
.imageDivCoverInMarket {
  width: 60%;

  position: relative;
  top: 350px;
  margin-left: 290px;
}
.h3coverInMarketKV {
  font-size: 45px;
  color: white;
  text-align: center;
  position: relative;
  top: 300px;
}
.h1coverInMarketKV {
  font-size: 70px;
  color: white;
  font-weight: 700;
  text-align: center;
  position: relative;
  top: 350px;
}
/* 1ST DIV */
.Div1stInMarketKV {
  height: 70vh;
  width: 100%;

  display: grid;
  margin-top: 100px;
  margin-bottom: 50px;
  grid-template-columns: 1fr 1fr;
}
.h1Div1stInMarketKV {
  font-size: 45px;
  font-weight: 600;
  padding-left: 100px;
}
.paraDiv1stInMarketKV {
  font-size: 30px;
  font-weight: 400;
  padding-left: 100px;
  color: #888888;
}
.buttonDiv1InMarketKV {
  margin-left: 170px;
  margin-top: 100px;
}
.ImageOfDataEnrichmentKV {
  width: 80%;
  height: 80%;
  margin-left: 50px;
}
.Div2ndInMarketKV {
  height: 70vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 300px;
}
.ImagePureConversionDiv2KV {
  height: 80%;
  width: 80%;
  margin-left: 50px;
}
.h1Div2InMarketPureConversionKV {
  font-size: 45px;
  font-weight: 600;
  margin-left: 100px;
}
.paraDiv2InMarketKV {
  margin-left: 100px;
  font-size: 25px;
  color: #888888;
}

.ButtonPureConversionDiv2KV {
  margin-left: 270px;
  margin-top: 100px;
}

.Div3InMarketKV {
  height: 70vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.ImageDiv3InMarketKV {
  height: 80%;
  width: 80%;
  margin-left: 50px;
}
.paraDiv3InMarketKV {
  margin-left: 100px;
  font-size: 25px;
  color: #888888;
}
.h1Div3InMarketKV {
  font-size: 45px;
  font-weight: 600;
  margin-left: 100px;
}
.ButtonPureConversionDiv3KV {
  margin-left: 170px;
  margin-top: 100px;
}

.Div4thInMarketKV {
  height: 30vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.ImageTalkToUsInMarketKV {
  width: 40%;
  height: 40%;
  margin-top: 70px;
  margin-left: 50px;
}
.h1talktousInmarketKV {
  padding-top: 100px;
}
.talktousButtonKV {
  margin-left: 100px;
  margin-top: 130px;
}
.smallCrdInMarketKV {
  min-height: 500px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.headingDiv4InMarket {
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  padding: 80px;
}
@media (min-width: 996px) and (max-width: 1280px) {
  .ImageDiv3InMarketKV {
    height: 30vh;
    width: 30vh;
    margin-left: 50px;
  }
  .Div3InMarketKV {
    margin-top: 100px;
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .Div2ndInMarketKV {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 0 !important;
    margin-right: 10% !important;
  }
  .Div1stInMarketKV {
    height: auto;
    width: 100%;

    display: grid;
    margin-top: 100px !important;
    margin-bottom: 100px !important;
    grid-template-columns: 1fr 1fr;
  }
  .ImageOfDataEnrichmentKV {
    width: 30vh;
    height: 30vh;
  }
  .ImagePureConversionDiv2KV {
    width: 30vh;
    height: 30vh;
  }
  .wallpaper3as {
    background-image: url(../images/inmarketops.png);
    background-size: cover;
    height: 50vh;
    width: 100%;
  }
}
@media (min-width: 700px) and (max-width: 996px) {
  .ImageDiv3InMarketKV {
    width: 50vw;
    height: 40vh;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 0;
  }
  .smallCrdInMarketKV {
    min-height: 500px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .Div3InMarketKV {
    margin-top: 100px;
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }
  .Div2ndInMarketKV {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 0 !important;
    margin-right: 10% !important;
  }
  .Div1stInMarketKV {
    height: auto;
    width: 100%;

    display: grid;
    margin-top: 100px !important;
    margin-bottom: 100px !important;
    grid-template-columns: 1fr;
  }
  .ImageOfDataEnrichmentKV {
    width: 50vw;
    height: 40vh;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 0;
  }
  .ImagePureConversionDiv2KV {
    width: 50vw;
    height: 40vh;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 0;
  }
  .wallpaper3as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 50vh;
    width: 100%;
  }
}
@media (min-width: 400px) and (max-width: 700px) {
  .ButtonPureConversionDiv2KV {
    margin-left: 0;
    margin-top: 100px;
  }
  .ButtonPureConversionDiv3KV {
    margin-left: 10%;
    margin-top: 100px;
  }
  .h1Div3InMarketKV {
    text-align: center;
    font-size: 35px;
    font-weight: 500;
    margin-left: 00px;
  }
  .paraDiv3InMarketKV {
    text-align: center;
    margin-left: 5%;
    font-size: 20px;
    color: #888888;
  }
  .h1Div1stInMarketKV {
    text-align: center;
    font-size: 35px;
    font-weight: 500;
    margin-left: -100px;
  }
  .paraDiv1stInMarketKV {
    text-align: center;
    margin-left: -10%;
    font-size: 20px;
    color: #888888;
  }
  .h1Div2InMarketPureConversionKV {
    text-align: center;
    font-size: 35px;
    font-weight: 500;
    margin-left: 0px;
  }
  .paraDiv2InMarketKV {
    text-align: center;
    margin-left: 5%;
    font-size: 20px;
    color: #888888;
  }
  .ImageDiv3InMarketKV {
    width: 40vh;
    height: 40vh;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .smallCrdInMarketKV {
    min-height: 500px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .Div3InMarketKV {
    margin-top: 100px;
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }
  .Div2ndInMarketKV {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 0 !important;
    margin-right: 10% !important;
  }
  .Div1stInMarketKV {
    height: auto;
    width: 100%;

    display: grid;
    margin-top: 100px !important;
    margin-bottom: 100px !important;
    grid-template-columns: 1fr;
  }
  .ImageOfDataEnrichmentKV {
    width: 40vh;
    height: 40vh;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .ImagePureConversionDiv2KV {
    width: 40vh;
    height: 40vh;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .wallpaper3as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 50vh;
    width: 100%;
  }
  .headingDiv4InMarket {
    font-size: 30px;
  }
}
@media (max-width: 400px) {
  .ButtonPureConversionDiv2KV {
    margin-left: 0;
    margin-top: 100px;
  }
  .ButtonPureConversionDiv3KV {
    margin-left: 0%;
    margin-top: 100px;
  }
  .ButtonPureConversionDiv3KV {
    margin-left: 1%;
    margin-top: 100px;
  }
  .h1Div3InMarketKV {
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    margin-left: 00px;
  }
  .paraDiv3InMarketKV {
    text-align: center;
    margin-left: 1%;
    font-size: 20px;
    color: #888888;
  }
  .h1Div1stInMarketKV {
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    margin-left: -100px;
  }
  .paraDiv1stInMarketKV {
    text-align: center;
    margin-left: -10%;
    font-size: 20px;
    color: #888888;
  }
  .h1Div2InMarketPureConversionKV {
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    margin-left: 0px;
  }
  .paraDiv2InMarketKV {
    text-align: center;
    margin-left: 1%;
    font-size: 20px;
    color: #888888;
  }
  .ImageDiv3InMarketKV {
    width: 40vh;
    height: 40vh;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .smallCrdInMarketKV {
    min-height: 500px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .Div3InMarketKV {
    margin-top: 100px;
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }
  .Div2ndInMarketKV {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 0 !important;
    margin-right: 10% !important;
  }
  .Div1stInMarketKV {
    height: auto;
    width: 100%;

    display: grid;
    margin-top: 100px !important;
    margin-bottom: 100px !important;
    grid-template-columns: 1fr;
  }
  .ImageOfDataEnrichmentKV {
    width: 40vh;
    height: 40vh;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .ImagePureConversionDiv2KV {
    width: 40vh;
    height: 40vh;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .wallpaper3as {
    background-image: url(../images/contentsyndication_background.png);
    background-size: cover;
    height: 50vh;
    width: 100%;
  }
  .headingDiv4InMarket {
    font-size: 30px;
  }
}
